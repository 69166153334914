/* eslint-disable max-len */

import enums from './enums';
import { validator, names as validator_names } from './validator';
import external_urls from './external_urls';
import page from './page';

const misc = {
    a_year_from_now: 'a year from now',
    abort: 'Abort',
    abort_proposal: 'Abort proposal',
    about_this_report: 'About this report',
    about_you: 'About you',
    accept: 'Accept',
    accept_proposal: 'Accept proposal',
    accepts: 'Accepts',
    account: 'Account',
    account_application_date: 'Account Application Date',
    account_application_form: 'Account Application Form',
    account_apps_in_progress: 'Accounts apps in progress',
    account_authorisation_date: 'Account Authorised Date',
    account_balance_last_updated: 'Account balance last updated',
    account_health: 'Account Health',
    account_name: 'Account Name',
    account_nickname: 'Account Nickname',
    account_number: 'Account Number',
    account_open_date: 'Account Open Date',
    account_reference_number: 'Account Reference Number',
    account_status: 'Account Status',
    account_type: 'Account Type',
    accountant: 'Accountant',
    accountants: 'Accountants',
    accounting_reference_day: 'Accounting Reference Day',
    accounting_reference_month: 'Accounting Reference Month',
    accounting_reference_year: 'Accounting Reference Year',
    accounts: 'Accounts',
    accounts_last_made_up_to: 'Accounts Last Made Up To',
    accounts_next_due: 'Accounts Next Due',
    accounts_next_made_up_to: 'Accounts Next Made Up To',
    accounts_overdue: 'Accounts Overdue',
    accrue_at: 'Accrue Date',
    action: 'Action',
    actions: 'Actions',
    active: 'Active',
    activities: 'Activities',
    activity: 'Activity',
    activity_date: 'Activity Date',
    add: 'Add',
    add_item: 'Add Item',
    add_new: 'Add New',
    add_note: 'Add Note',
    add_recipient: 'Add Recipient',
    add_to: 'Add to',
    add_to_both: 'add to both',
    add_to_shortlist: 'Add to shortlist',
    additional: 'Additional',
    additional_details_on_this_position_are_pending_the_allotment_of_shares:
        'Additional details on this position are pending the allotment of shares',
    additional_fee: 'Additional Fee',
    additional_fee_investee_company: 'Additional Fee Investee Company',
    additional_information: 'Additional information',
    additional_recipients: 'Additional Recipients',
    additional_recipients_will_be_ccd: 'Additional recipients will be CC’d',
    additional_terms_and_conditions: 'Additional T&Cs',
    additional_user: 'Additional User',
    additional_user_access: 'Additional User Access',
    additional_users: 'Additional Users',
    address: 'Address',
    address_line: 'Address Line',
    address_name: 'Address Name',
    address_search_is_currently_unavailable:
        'Address search is currently unavailable, please enter the address manually.',
    address_search_unavailable: 'Address search is currently unavailable, please enter your full address manually',
    address_type: 'Address Type',
    addresses: 'Addresses',
    administration: 'Administration',
    administration_fee: 'Administration Fee',
    advised: 'Advised',
    adviser: 'Adviser',
    adviser_account: 'Adviser Account',
    adviser_accounts: 'Adviser Accounts',
    adviser_activity: 'Adviser Activity',
    adviser_details: 'Adviser Details',
    adviser_fees: 'Adviser Fees',
    adviser_fees_are_X_of_investment_amount: 'Adviser fees are {percentage}% of the investment amount',
    adviser_fees_will_be_reset: 'Adviser fees will be reset',
    adviser_firm_name: 'Adviser Firm Name',
    adviser_id: 'Adviser ID',
    adviser_management: 'Adviser Management',
    adviser_must_set_fees:
        'Your financial adviser will need to edit this entry to include their fees before this shortlist entry can be proposed',
    adviser_name: 'Adviser Name',
    adviser_person_name: 'Adviser Person Name',
    advisers: 'Advisers',
    after: 'After',
    agreement: 'Agreement',
    agreements: 'Agreements',
    aic_sector: 'AIC Sector',
    alert: 'Alert',
    alert_text: 'Alert Text',
    alert_type: 'Alert Type',
    all: 'All',
    all_clients: 'All clients',
    all_clients_have_been_added_to_the_visibility_group: 'All clients have been added to the visibility group',
    all_info_news_and_docs_will_always_be_available_via_the_platform:
        'All information, news and documents will always be available via the GrowthInvest platform. In addition, you can opt in to receive communications directly. Please refer to the company’s Privacy Policy in the Prospectus. It contains important information about how the company will use your personal data and the rights you have.',
    all_transactions: 'All Transactions',
    allot: 'Allot',
    allot_shares: 'Allot Shares',
    allotment_at: 'Allotment Date',
    allotted: 'Allotted',
    allotting: 'Allotting',
    alt_text: 'Alt Text',
    alternatives: 'Alternatives',
    aml_check_completed: 'AML Check Completed',
    aml_check_incomplete: 'AML Check Incomplete',
    amount: 'Amount',
    amount_per_annum: 'Amount per annum (£)',
    amount_pledged: 'Amount Pledged',
    amount_raised: 'Amount Raised',
    and: 'and',
    annual: 'Annual',
    annual_adviser_fee: 'Annual Adviser Fee',
    annual_adviser_fee_vat: 'Annual Adviser Fee VAT',
    annual_fee: 'Annual Fee',
    annual_management: 'Annual Management',
    annual_management_charge: 'Annual Management Charge',
    annual_management_fee: 'Annual Management Fee',
    annual_platform_fee: 'Annual Platform Fee',
    annual_return_last_made_up_to: 'Annual Return Last Made Up To',
    annual_return_next_due: 'Annual Return Next Due',
    annual_return_next_made_up_to: 'Annual Return Next Made Up To',
    annual_return_overdue: 'Annual Return Overdue',
    answer: 'Answer',
    api_remote_id: 'API Remote ID',
    applicable_tax_relief: 'Applicable Tax Relief',
    application: 'Application',
    application_amount: 'Application amount',
    application_date: 'Application Date',
    application_form: 'Application Form',
    application_form_sent: 'Application Form Sent',
    application_form_will_be_signed_manually: 'Will the application form be signed manually?',
    application_on: 'Application Date',
    application_signed_at: 'Application Signed Date',
    application_signed_date: 'Application Signed Date',
    application_status: 'Application Status',
    applications: 'Applications',
    applications_in_progress: 'applications in progress',
    applied: 'Applied',
    apply: 'Apply',
    apply_filters: 'Apply filters',
    apply_tax_relief: 'Apply Tax Relief',
    apply_to_all: 'Apply to all',
    apply_vat: 'Apply VAT',
    appropriateness: 'Appropriateness',
    are_you_receiving_advice_from_your_adviser:
        "Are you receiving advice from your financial intermediary on the investments you'll be making on this platform?",
    are_you_sure: 'Are you sure?',
    ask_the_team: 'Ask the Team',
    assets_being_onboarded: 'Assets being onboarded',
    assets_onboarded_this_year: 'Assets onboarded this year',
    associated_advisers: 'Associated advisers',
    at: 'at',
    attempts: 'Attempts',
    aua: 'AUA',
    aum: 'AUM',
    auth_activity: 'Auth Activity',
    auth_client_id: 'Auth Client ID',
    auth_client_secret: 'Auth Client Secret',
    authorisation_by: 'Authorisation By',
    authorisation_date: 'Authorisation Date',
    authorisation_signed_off_by: 'Authorised Signed Off By',
    authorise: 'Authorise',
    authorised: 'Authorised',
    authorised_at: 'Authorisation Date',
    authorised_by: 'Authorised By',
    authorised_date: 'Authorisation Date',
    authorised_investment_accounts: 'Authorised investment accounts',
    authorised_on: 'Authorised Date',
    autosave: 'Autosave',
    available: 'Available',
    available_redistribution: 'Available Redistribution',
    avatar: 'Avatar',
    awaiting_adviser_acceptance: 'Awaiting adviser acceptance',
    awaiting_adviser_fees: 'Awaiting adviser fees',
    awaiting_approval: 'Awaiting Approval',
    awaiting_client_acceptance: 'Awaiting client acceptance',
    awaiting_confirmation: 'Awaiting Confirmation',
    awaiting_funding: 'Awaiting funding',
    awaiting_funds: 'Awaiting funds',
    awaiting_investment: 'Awaiting investment',
    awaiting_signatories: 'Awaiting signatories',
    awaiting_signatures: 'Awaiting signatures',
    back: 'Back',
    back_to: 'Back to',
    back_to_login: 'Back to login',
    background: 'Background',
    balance: 'Balance',
    balance_updated_at: 'Balance Updated Date',
    balanced: 'Balanced',
    bank_account: 'Bank Account',
    bank_accounts: 'Bank Accounts',
    bank_name: 'Bank Name',
    basic_information: 'Basic Information',
    before: 'Before',
    before_you_can_make_an_investment_on_the_platform:
        'Before you can make an investment on the platform, you need to submit an application for an investment account. Each of the tabs above has some information we need - the ones marked with a red dot still need your attention before you can submit your application. Once you’ve supplied the information, click the Apply button and our team will process your application.',
    billing: 'Billing',
    bio: 'Biography',
    biography: 'Biography',
    blacklist: 'Blacklist',
    blocks: 'Blocks',
    blocks_allotted: 'Blocks Allotted',
    body: 'Body',
    branding: 'Branding',
    broker: 'Broker',
    brokers: 'Brokers',
    browse: 'Browse',
    builder: 'Builder',
    bulk_import: 'Bulk Import',
    bulk_share_allotment: 'Bulk Share Allotment',
    bulk_update: 'Bulk Update',
    business_description: 'Business Description',
    business_sector: 'Business Sector',
    business_stage: 'Business Stage',
    buyback_discount: 'Buyback Discount',
    calculate_based_on_gross_investment_amount: 'Calculate based on gross investment amount',
    calculated_by_application_amount: 'Calculated by adding fees',
    calculated_by_cleared_funds: 'Calculated by deducting fees',
    calculation: 'Calculation',
    can_login: 'Can this user log into the platform?',
    cancel: 'Cancel',
    cancel_removing_this_item: 'Cancel removing this item',
    cant_find_what_youre_looking_for: "Can't find what you're looking for?",
    cards: 'Cards',
    carried_interest_provision: 'Carried Interest Provision',
    cash: 'Cash',
    cash_balance: 'Cash Balance',
    cash_on_platform: 'Cash on platform',
    cash_receipts_and_withdrawals: 'Cash receipts and withdrawals',
    categorisation: 'Categorisation',
    categorisation_authorisation_date: 'Categorisation authorisation date',
    categorisation_expiration_date: 'Categorisation expiration date',
    categorisation_expiration_date_warning:
        'If the category expiration date is not set, or is set in the past, the user will need to recategorise when logging into the platform for the first time',
    categorisation_results_not_found_for: 'Categorisation results not found for',
    category: 'Category',
    category_authorised_on: 'Category authorised on',
    category_expires_on: 'Category expires on',
    certificate_of_verification_of_identity: 'Certificate of Verification of Identity',
    certification_date: 'Date Certified',
    cessation_on: 'Cessation Date',
    cgt_relief: 'CGT Relief',
    change: 'Change',
    change_category: 'Change Category',
    change_file: 'Change File',
    change_hero_image: 'Change Hero Image',
    change_image: 'Change Image',
    change_logo: 'Change Logo',
    change_this_month: 'Change this month',
    change_this_year: 'Change this year',
    change_view: 'Change View',
    check_your_email_to_setup_mfa: 'Check your email to setup MFA',
    check_your_email_to_verify_your_email_address: 'Check your email to verify your email address',
    check_your_search_for_typos: 'Check your search for typos',
    checkbox: 'Checkbox',
    child_organisations: 'Child Organisations',
    city: 'City',
    city_of_birth: 'City of Birth',
    clear: 'Clear',
    cleared_funds: 'Cleared Funds',
    cleared_funds_required: 'Cleared funds required',
    client: 'Client',
    client_categorisation: 'Client Categorisation',
    client_id: 'Client ID',
    client_name: 'Client Name',
    client_partner: 'Client Partners',
    client_platform_accounts: 'Client platform accounts',
    client_portfolio: 'Client Portfolio',
    client_profiles_incomplete: 'Client profiles incomplete',
    client_reference: 'Client Reference',
    client_registrations: 'Client Registrations',
    clients: 'Clients',
    clients_in_this_visibility_group: 'Clients in this visibility group',
    close: 'Close',
    close_at: 'Close Date',
    close_date: 'Close Date',
    closed: 'Closed',
    closed_at: 'Close Date',
    closed_offers_cannot_be_proposed: 'Closed offers cannot be proposed',
    closing_in: 'Closing in',
    co_invest: 'Co-Invest',
    collapse: 'Collapse',
    colours: 'Colours',
    column: 'Column',
    column_visibility: 'Column Visibility',
    columns: 'Columns',
    combine: 'Combine',
    combine_portfolios: 'Combine Portfolios',
    combined_cash_balance: 'Combined Cash Balance',
    commitment: 'Commitment',
    companies: 'Companies',
    companies_house: 'Companies House',
    companieshouse: 'Companies House',
    company: 'Company',
    company_details: 'Company Details',
    company_name: 'Company Name',
    company_number: 'Company Number',
    company_overview: 'Company Overview',
    company_status: 'Company Status',
    company_status_detail: 'Company Status Detail',
    complete_application: 'Complete Application',
    complete_proposal: 'Complete proposal',
    completed: 'Completed',
    compress: 'Compress',
    condition: 'Condition',
    config: 'Config',
    confirm: 'Confirm',
    confirm_that_you_accept_this_declaration: 'Confirm that you accept this declaration to continue',
    confirmation_of_verification_of_identity: 'Confirmation of Verification of Identity',
    confirmation_statement_last_made_up_to: 'Confirmation Statement Last Made Up To',
    confirmation_statement_next_due: 'Confirmation Statement Next Due',
    confirmation_statement_next_made_up_to: 'Confirmation Statement Next Made Up To',
    confirmation_statement_overdue: 'Confirmation Statement Overdue',
    consolidated_view: 'Consolidated View',
    contact_details: 'Contact Details',
    contact_number: 'Contact Number',
    contact_number_type: 'Contact Number Type',
    contact_numbers: 'Contact Numbers',
    contains: 'Contains',
    continue: 'Continue',
    continue_where_you_left_off: 'Continue where you left off',
    control: 'Control',
    conversion_price: 'Conversion Price',
    conversion_valuation_cap: 'Conversion Valuation Cap',
    conversion_valuation_floor: 'Conversion Valuation Floor',
    converts_on: 'Converts On',
    copied: 'Copied',
    copy: 'Copy',
    copying: 'Copying',
    corporate_action: 'Corporate Action',
    count: 'Count',
    country: 'Country',
    country_and_place_of_birth: 'Country and Place of Birth',
    country_code: 'Country Code',
    county: 'County',
    coupon: 'Coupon',
    coupon_settlement_period: 'Coupon Settlement Period',
    covi: 'COVI',
    create: 'Create',
    create_adviser: 'Create Adviser',
    create_investment_offer: 'Create Investment Offer',
    create_investor: 'Create Investor',
    create_media_library_item: 'Create Media Library Item',
    create_new: 'Create New',
    create_proposal: 'Create Proposal',
    create_shortlist: 'Create Shortlist',
    create_user: 'Create User',
    created: 'Created',
    created_at: 'Created Date',
    created_by: 'Created By',
    created_by_id: 'Created By',
    created_date: 'Created Date',
    creating: 'Creating',
    creation_on: 'Creation Date',
    criteria: 'Criteria',
    cumulative_loan_contribution: 'Cumulative Loan Contribution',
    cumulative_net_contributions: 'Cumulative Net Contributions',
    currency: 'Currency',
    current: 'Current',
    current_amount_raised: 'Current amount raised',
    current_nav: 'Current NAV',
    current_nav_price: 'Current NAV Price',
    current_return_gross: 'Current Return Gross',
    current_return_net: 'Current Return Net',
    current_share_price: 'Current Share Price',
    current_value: 'Current Value',
    current_value_irr: 'Current Value IRR',
    current_value_moic: 'Current Value MOIC',
    currently_showing: 'Currently showing',
    custodian: 'Custodian',
    custodians: 'Custodians',
    custodied: 'Custodied',
    custody_account: 'Custody Account',
    custody_account_reference: 'Custody Account Reference',
    custody_accounts: 'Custody Accounts',
    custom_app_url: 'Custom App URL',
    daily_active_users: 'Daily Active Users',
    danger_this_action_cannot_be_undone: 'Danger - this action cannot be undone',
    dashboard: 'Dashboard',
    data: 'Data',
    data_entry: 'Data Entry',
    data_management: 'Data Management',
    data_not_found: 'Data not found',
    date: 'Date',
    date_entered: 'Date Entered',
    date_moved_into_address: 'Date moved into address',
    date_moved_out_of_address: 'Date moved out of address',
    date_now: 'Date Now',
    date_of_birth: 'Date of Birth',
    date_of_cessation: 'Date of Cessation',
    date_of_creation: 'Date of Creation',
    date_of_transfer: 'Date of Transfer',
    date_range: 'Date Range',
    day: 'Day',
    days: 'Days',
    days_until_end_of_tax_year: 'Days until end of tax year',
    deadline: 'Deadline',
    deadline_at: 'Deadline Date',
    deadline_details: 'Deadline Details',
    declaration: 'Declaration',
    declarations: 'Declarations',
    default_investment_percentage: 'Default Investment Percentage',
    delete: 'Delete',
    deleted: 'Deleted',
    denomination: 'Denomination',
    deployment_by_yearend: 'Deployment by year end?',
    deployment_timeline: 'Deployment Timeline',
    derivative_exercise_type: 'Derivative Exercise Type',
    derivative_settlement_period: 'Derivative Settlement Period',
    derivative_settlement_type: 'Derivative Settlement Type',
    description: 'Description',
    destination: 'Destination',
    detail: 'Detail',
    details: 'Details',
    difference: 'Difference',
    digital_application: 'Digital Application',
    digital_signature: 'Digital Signature',
    disable_cobranded_reports: 'Disable Co-Branded Reports',
    disable_investor_welcome_page: 'Disable Investor Welcome Page',
    disabled: 'Disabled',
    discard: 'Discard',
    disclaimer: 'Disclaimer',
    discount_to_nav: 'Discount to NAV',
    discounts_available: 'Discounts Available',
    discretionary: 'Discretionary',
    dismiss: 'Dismiss',
    display_options: 'display options',
    display_section: 'Display Section',
    disposal_letter: 'Disposal Letter',
    disposal_letter_date: 'Disposal Letter Date',
    disposal_tax_year: 'Disposal Tax Year',
    disposal_type: 'Disposal Type',
    disposals: 'Disposals',
    disposals_failures_report: 'Disposals - Failures Report',
    disposals_sales_report: 'Disposals - Sales Report',
    disposition: 'Disposition',
    disposition_on: 'Disposition Date',
    disposition_result: 'Disposition Result',
    disposition_tax_year: 'Disposition Tax Year',
    distribution: 'Distribution',
    distributions: 'Distributions',
    dividend_cover: 'Dividend Cover',
    dividend_frequency: 'Dividend Frequency',
    dividend_growth: 'Dividend Growth',
    dividend_policy: 'Dividend Policy',
    dividend_reinvestment_offered: 'Dividend Reinvestment Offered',
    dividend_start_on: 'Dividend Start On',
    dividend_type: 'Dividend Type',
    dividend_yield: 'Dividend Yield',
    dividends: 'Dividends',
    do_you_have_an_adviser: 'Do you have a financial intermediary?',
    document: 'Document',
    document_date: 'Document Date',
    documents: 'Documents',
    does_not_contain: 'Does not contain',
    does_this_product_qualify_as_an_esg_investment_product: 'Does this product qualify as an ESG investment product?',
    does_this_product_qualify_for_iht_relief: 'Does this product qualify for IHT relief?',
    domiciled: 'Domiciled',
    done: 'Done',
    download: 'Download',
    download_and_manage_documents: 'Download and manage documents',
    download_as_pdf: 'Download as PDF',
    download_as_xlsx: 'Download as XLSX',
    download_categorisation_results: 'Download categorisation results',
    download_report: 'Download Report',
    download_template: 'Download Template',
    downloads: 'Downloads',
    dri: 'DRI',
    dri_available: 'DRI Available',
    dri_enrolled: 'DRI Enrolled',
    dri_enrolled_at: 'DRI Enrolment Date',
    dri_management: 'DRI Management',
    dri_share_type: 'DRI Share Type',
    drop_avatar_here_to_upload: 'Drop avatar here to upload',
    drop_background_here_to_upload: 'Drop background here to upload',
    drop_favicon_here_to_upload: 'Drop favicon here to upload',
    drop_file_here_to_upload: 'Drop file here to upload',
    drop_files_here_to_upload: 'Drop files here to upload',
    drop_image_here_to_upload: 'Drop image here to upload',
    drop_logo_here_to_upload: 'Drop logo here to upload',
    duration_of_holding: 'Duration of Holding',
    dynamic_form: 'Dynamic Form',
    dynamic_form_builder: 'Dynamic Form Builder',
    dynamic_form_debugger: 'Dynamic Form Debugger',
    dynamic_value: 'Dynamic Value',
    dynamic_values_are_calculated_when_the_editor_content_is_displayed:
        'Dynamic values are calculated when the editor content is displayed',
    early_bird_close_at: 'Early Bird Discount Deadline Date',
    early_bird_discount: 'Early Bird Discount',
    early_bird_discount_description: 'Early Bird Discount Description',
    early_bird_nominal: 'Early Bird Nominal',
    early_bird_offer: 'Early Bird Offer',
    early_bird_open_at: 'Early Bird Open Date',
    ebitda_forecast: 'EBITDA Forecast',
    edit: 'Edit',
    edit_this_address: 'Edit this address',
    edit_this_item: 'Edit this item',
    effective_at: 'Effective Date',
    effective_on: 'Effective On',
    eis_and_seis: 'EIS and SEIS',
    eis_and_seis_funds: 'EIS and SEIS Funds',
    eis_certificate_received_on: 'EIS Certificate Received Date',
    elevator_pitch: 'Elevator Pitch',
    email: 'Email',
    email_address: 'Email Address',
    employment_status: 'Employment Status',
    ends_with: 'Ends with',
    enter_a_comma_separated_list: 'Enter a comma separated list (CSV)',
    enter_address_manually: 'Enter address manually',
    enter_company_info_manually: 'Enter company information manually',
    enter_the_categorisation_expiration_date: 'Enter the categorisation expiration date',
    enter_the_complete_company_number: 'Enter a complete company number to find a company',
    enter_the_item_details: 'Enter the item details',
    enter_valid_email_address: 'Enter a valid email address',
    enter_valid_fca_id: 'Enter a valid FCA Identification Number',
    enter_valid_full_name: 'Enter a valid first and last name',
    enter_valid_iban_number: 'Enter a valid IBAN number',
    enter_valid_job_title: 'Enter a valid job title',
    enter_valid_linkedin_username: 'Enter a valid LinkedIn username',
    enter_valid_money: 'Enter a valid monetary value',
    enter_valid_national_insurance_number: 'Enter a valid National Insurance number',
    enter_valid_phone_number: 'Enter a valid phone number',
    eq: 'equals',
    equity_holding: 'Equity Holding',
    equity_issued: 'Equity Offer',
    equity_offer: 'Equity Offer',
    error: 'Error',
    error_404_item_not_found: "Error 404. The item you're looking for can't be found",
    error_404_page_not_found: "Error 404. The page you're looking for can't be found.",
    error_debugger: 'Error Debugger',
    error_getting_clients: 'Error getting clients',
    error_getting_offer: 'Error getting offer',
    error_getting_proposal: 'Error getting proposal',
    error_getting_proposals: 'Error getting proposals',
    error_getting_shortlist: 'Error getting shortlist',
    error_getting_shortlists: 'Error getting shortlists',
    error_saving_proposal: 'Error saving proposal',
    error_saving_shortlist: 'Error saving shortlist',
    esg_and_sustainable_investments: 'ESG and Sustainable Investments',
    esg_qualifying: 'ESG Qualifying',
    est_nav: 'NAV Estimate',
    estate_contact: 'Estate Contact',
    estate_contacts: 'Estate Contacts',
    estate_of: 'Estate of',
    ex_date: 'Ex Date',
    existing_investor_close_at: 'Existing Shareholder Discount Deadline Date',
    existing_investor_close_description: 'Existing Shareholder Discount Description',
    existing_investor_initial: 'Existing Investor Initial',
    existing_investor_initial_fee: 'Existing Investor Initial Fee',
    existing_investors: 'Existing Investors',
    existing_nominee_reference: 'Existing Nominee Reference',
    existing_shareholder_discount: 'Existing Shareholder Discount',
    existing_shareholder_reference: 'Existing Shareholder Reference',
    exit_strategy: 'Exit Strategy',
    exits: 'Exits',
    exits_and_disposals: 'Exits and Disposals',
    expand: 'Expand',
    expansion_panel: 'Expansion Panel',
    expires_in: 'Expires in',
    exporting_as_pdf: 'Exporting as PDF',
    exporting_as_xlsx: 'Exporting as XLSX',
    external: 'External',
    external_client_ref: 'External Client Ref',
    external_id: 'External ID',
    external_ref: 'External Ref',
    external_url: 'External URL',
    external_users: 'External Users',
    facebook: 'Facebook',
    failed_to_delete: 'Failed to delete',
    failed_to_load_correctly: 'failed to load correctly',
    failed_to_load_terms_and_conditions: 'Failed to load Terms and Conditions',
    failure_on: 'Failure Date',
    failures: 'Failures',
    false: 'False',
    favicon: 'Favicon',
    fca_id: 'FCA ID',
    fca_identity: 'FCA Identity',
    fca_number: 'FCA Number',
    fca_number_missing: 'FCA Number Missing',
    feature_settings: 'Feature Settings',
    fee: 'Fee',
    fee_collection: 'Fee Collection',
    fee_illustration: 'Fee Illustration',
    fee_illustration_request_sent_successfully: 'Fee illustration request sent successfully',
    fee_inclusive_of_vat_if_applicable: 'Fee inclusive of VAT if applicable',
    fee_plan: 'Fee Plan',
    fee_plan_management: 'Fee Plan Management',
    fee_plans: 'Fee Plans',
    fee_summary: 'Fee Summary',
    fees: 'Fees',
    fees_and_charges: 'Fees & Charges',
    fees_are_subject_to_minimums: 'Fees are subject to minimums',
    file: 'File',
    file_not_found: 'File not found',
    file_type_not_allowed: 'File type not allowed',
    files: 'Files',
    fill_in_form_to_create_user: 'Fill in the form to create a new platform user',
    filter: 'Filter',
    filter_by_investor: 'Filter by Investor',
    filters: 'Filters',
    financial_intermediary: 'Financial Intermediary',
    financial_services_register_reference_numbers_are_required:
        'Financial Services Register reference numbers are required for users with the Adviser role. If you do not provide a reference number, this user will not be able to be set as the Primary Contact for an Investor.',
    financial_transactions: 'Financial Transactions',
    find_by: 'Find by',
    finish: 'Finish',
    finish_editing: 'Finish editing',
    finish_editing_before_setting_the_primary: 'Finish editing before setting the primary',
    firm_name: 'Firm Name',
    first_name: 'First Name',
    flow: 'Flow',
    form: 'Form',
    format: 'Format',
    found: 'Found',
    from_this_shortlist: 'from this shortlist',
    full_name: 'Full Name',
    full_platform_terms_and_conditions: 'Full Platform Terms & Conditions',
    fund: 'Fund',
    fund_cash_balance: 'Fund Cash Balance',
    fund_denomination: 'Fund Denomination',
    fund_deployment: 'Fund Deployment',
    fund_investment: 'Fund Investment',
    fund_manager: 'Fund Manager',
    fund_manager_details: 'Fund Manager Details',
    fund_managers: 'Fund Managers',
    fund_name: 'Fund Name',
    fund_or_custodian: 'Fund/Custodian',
    fund_overview: 'Fund Overview',
    fund_status: 'Fund Status',
    fund_structure: 'Fund Structure',
    fund_tax_status: 'Fund Tax Status',
    fund_type: 'Fund Type',
    funding_required_before_progressing: 'Funding required before progressing',
    funding_status: 'Funding Status',
    funding_to_date: 'Funding to Date',
    fundManagers: 'Fund Managers',
    funds: 'Funds',
    funds_cleared: 'Funds Cleared',
    funds_cleared_at: 'Date Funds Cleared',
    funds_raised: 'Funds Raised',
    funds_status: 'Funds Status',
    generate_pdf: 'Generate PDF',
    generic_system_error: 'System Error: Please try again later',
    gi_account_application_form: 'GrowthInvest Account Application Form',
    gi_annual_charge: 'GrowthInvest Annual Charge',
    gi_app_form: 'GI App Form',
    gi_discount: 'GI Discount',
    gi_existing_investor_discount: 'GI Existing Investor Discount',
    gi_platform_agreement: 'GrowthInvest Platform Agreement',
    gi_ref: 'GI Reference',
    gi_transaction_ref: 'GI Transaction Ref',
    giin: 'GIIN',
    give_bank_account_name: 'Give this bank account a name',
    give_your_new_shortlist_a_name: 'Give your new shortlist a name',
    global: 'Global',
    go_back: 'Go back',
    go_home: 'Go Home',
    go_to_company: 'Go to Company',
    go_to_fund: 'Go to Fund',
    go_to_investment: 'Go to Investment',
    go_to_product: 'Go to Product',
    go_to_proposals: 'Go to Proposals',
    go_to_shortlist: 'Go to Shortlist',
    go_to_tranche: 'Go to Tranche',
    gp_share: 'GP Share',
    gross_dividend: 'Gross Dividend',
    gross_gearing: 'Gross Gearing',
    gross_investment: 'Gross Investment',
    gross_investment_amount: 'Gross Investment Amount',
    gross_investment_value: 'Gross Investment Value',
    gross_proceeds: 'Gross Proceeds',
    growthinvest: 'GrowthInvest',
    growthinvest_discount: 'GrowthInvest Discount',
    growthinvest_marketing_updates: 'GrowthInvest Marketing updates',
    growthinvest_privacy_agreement: 'GrowthInvest Privacy agreement',
    growthinvest_technical_regulatory_updates: 'GrowthInvest Technical & Regulatory updates',
    gte: 'is more than or equal to',
    has_been_added: 'has been added',
    has_been_added_to: 'has been added to',
    has_charges: 'Has Charges?',
    has_insolvency_history: 'Has Insolvency History?',
    have_you_received_financial_advice_on_this_investment: 'Have you received financial advice on this investment?',
    header_image: 'Header Image',
    heading: 'Heading',
    headline: 'Headline',
    hello: 'Hello',
    heres_what_we_found: "Here's what we found",
    hero_image: 'Hero Image',
    hide: 'Hide',
    hide_detail: 'Hide Detail',
    historical_dividends_only: 'Only include historical dividends',
    history: 'History',
    hmrc_ref: 'HMRC Reference',
    holding: 'Holding',
    holdings: 'Holdings',
    how_much_money_will_you_propose_your_client_invests: 'How much money will you propose your client invests?',
    how_much_money_will_your_client_pay_to_make_this_investment:
        'How much money will your client pay to make this investment?',
    how_much_would_you_like_to_invest: 'How much would you like to invest?',
    how_would_you_like_to_redistribute_funds:
        'If one of the products on this investment closes before your application is completed, how would you like to redistribute the funds?',
    i_accept_the_risk_of_losing_money:
        'I accept that the investments to which the promotions will relate may expose me to a significant risk of losing all of the money or other assets invested. I am aware that it is open to me to seek advice from an authorised person who specialises in advising on non-readily realisable securities.',
    i_declare_i_have_not_invested_more_than_10_percent:
        'In the preceding twelve months, I have not invested more than 10% of my net assets in non-readily realisable securities; and',
    i_declare_i_will_not_invest_more_than_10_percent:
        'I undertake that in the next twelve months I will not invest more than 10% of my net assets in non-readily realisable securities',
    i_declare_that_i_qualify_as_a_retail_investor_because:
        'I declare that I qualify as a retail (restricted) investor because:',
    i_do_not_have_an_adviser_that_is_execution_only_or_providing_advice:
        'I do not have a financial intermediary that is providing advice, or execution only services, or I am setting up as a direct client of Growth Invest.',
    i_dont_fit_into_any_of_the_categories_described_on_this_page:
        "I don't fit into any of the categories described on this page.",
    i_dont_want_to_see_this_client: "I don't want to see this client",
    i_have_an_adviser_that_is_execution_only_or_providing_advice:
        'I have a financial intermediary that is providing advice or execution only services.',
    i_have_an_annual_income_of_more_than:
        'I have an annual income of more than £100k or net assets of more than £250k.',
    i_have_experience_of_investing_in_early_stage_companies: 'I have experience of investing in early-stage companies.',
    i_have_read_and_understand_the_risk_warning: 'I have read and understand the ',
    i_make_this_statement_so_that_i_can_receive_promotional_communications:
        'I make this statement so that I can receive promotional communications relating to non-readily realisable securities as a retail (restricted) investor.',
    i_wish_to_be_treated_as_a_retail_investor: 'I wish to be treated as a retail (restricted) investor.',
    iban: 'IBAN',
    icon: 'Icon',
    icons: 'Icons',
    id: 'ID',
    if_the_applicant_or_a_close_associate_or_family_member_is_a_pep:
        'If the applicant or a close associate or family member is a PEP, please mark as “Yes”. It is possible for PEPs to hold a GrowthInvest account, though we will require some further information.',
    if_this_account_has_a_power_of_attorney:
        'If this account has a Power of Attorney in place, please mark as "Yes" and enter the details of the Power of Attorney in the Intermediary section.',
    if_you_continue_the_adviser_fees_will_be_reset_and_you:
        'If you proceed, the adviser fees will be reset and you will need to re-enter them before this proposal can continue.',
    if_you_continue_the_adviser_fees_will_be_reset_and_your_adviser:
        'If you proceed, the adviser fees will be reset and your adviser will need to re-enter them before this shortlist entry can be proposed.',
    ifa: 'IFA',
    ignore: 'Ignore',
    iht: 'IHT',
    iht_and_estate_planning_services: 'IHT and Estate Planning Services',
    iht_relief: 'IHT Relief',
    iht_sector: 'IHT Sector',
    iht_type: 'IHT Type',
    im: 'IM',
    im_a_high_net_worth_investor: "I'm a high net worth investor",
    im_a_professional_investor_employed_in_a_regulated_firm:
        "I'm a professional investor employed in a regulated firm or equivalent.",
    im_a_sophisticated_investor: "I'm a sophisticated investor",
    im_an_investment_professional: "I'm an investment professional",
    im_being_advised: "I'm being advised by a financial adviser about my investments on this platform",
    im_not_receiving_professional_advice: "I'm not receiving professional advice on the investments I'm making",
    im_sure: "I'm sure",
    image: 'Image',
    images: 'Images',
    import: 'Import',
    in: 'In',
    in_custody: 'In Custody',
    in_order_to_create_your_account_well_need_some_more_information_about_you:
        "In order to create your account, we'll need some more information about you:",
    in_order_to_create_your_account_well_need_some_more_information_from_you:
        "In order to create your account, we'll need some more information from you:",
    in_order_to_create_your_account_well_need_to_add_your_contact_information:
        "In order to create your account, we'll need to add your contact information:",
    in_order_to_create_your_account_well_need_to_add_your_primary_address:
        'In order to create your account, we need to add your primary address',
    in_progress: 'In Progress',
    income: 'Income',
    income_details: 'Income Details',
    income_tax_relief: 'Income Tax Relief',
    incomplete: 'Incomplete',
    incorrect_data_type: 'Incorrect Data Type',
    indicative_realisation: 'Indicative Realisation',
    individual_name: 'Individual Name',
    industry_details: 'Industry Details',
    industry_verticals: 'Industry Verticals',
    infected: 'Infected',
    information: 'Information',
    information_request_sent_successfully: 'Information request sent successfully',
    information_required: 'Information Required',
    initial: 'Initial',
    initial_adviser_fee: 'Initial Adviser Fee',
    initial_adviser_fee_vat: 'Initial Adviser Fee VAT',
    initial_charge: 'Initial Charge',
    initial_fee: 'Initial Fee',
    initial_investment: 'Initial Investment',
    initial_platform_fee: 'Initial Platform Fee',
    initial_registration_email: 'Initial registration email',
    initial_share_price: 'Initial Share Price',
    initial_share_quantity: 'Initial Share Quantity',
    initial_tax_relief: 'Initial Tax Relief',
    initial_value: 'Initial Value',
    initialising: 'Initialising',
    input: 'Input',
    input_type: 'Input type',
    insert: 'Insert',
    instead: 'instead',
    instrument_type: 'Instrument Type',
    interest_dividend: 'Interest/Dividend',
    intermediary: 'Intermediary',
    intermediary_charges: 'Intermediary Charges',
    intermediary_details: 'Intermediary Details',
    intermediary_fees: 'Intermediary Fees',
    internal: 'Internal',
    introducer: 'Introducer',
    introducer_name: 'Introducer Name',
    introducers: 'Introducers',
    introduction_letter: 'Introduction Letter',
    invalid: 'Invalid',
    invest: 'Invest',
    invest_now: 'Invest Now',
    invested: 'Invested',
    invested_amount: 'Invested Amount',
    invested_on: 'Invested Date',
    investee_additional: 'Investee Additional',
    investee_additional_fee: 'Investee Additional Fee',
    investee_company_charge_initial: 'Investee Company Charge Initial',
    investee_company_fees: 'Investee Company Fees',
    investee_initial: 'Investee Initial',
    investee_initial_fee: 'Investee Initial Fee',
    investee_ongoing: 'Investee Ongoing',
    investee_ongoing_fee: 'Investee Ongoing Fee',
    investment: 'Investment',
    investment_account: 'Investment Account',
    investment_account_applied_at: 'Investment Account Application Date',
    investment_account_authorised_at: 'Investment Account Authorisation Date',
    investment_account_opened_at: 'Investment Account Open Date',
    investment_amount: 'Investment Amount',
    investment_amount_rounded: 'The investment amount has been rounded up',
    investment_count: 'No. Investments',
    investment_date: 'Investment Date',
    investment_events: 'Investment events',
    investment_experience: 'Investment Experience',
    investment_flow: 'Investment Flow',
    investment_focus: 'Investment Focus',
    investment_name: 'Investment Name',
    investment_notifications: 'Investment Notifications',
    investment_offer: 'Investment Offer',
    investment_offers: 'Investment Offers',
    investment_proposals: 'Investment Proposals',
    investment_sector: 'Investment Sector',
    investment_shortlist: 'Investment Shortlist',
    investment_strategy: 'Investment Strategy',
    investment_type: 'Investment Type',
    investment_updates: 'Investment updates',
    investments: 'Investments',
    investments_advisory_fess: 'Investments Advisory Fees',
    investor: 'Investor',
    investor_activity: 'Investor Activity',
    investor_appropriateness_test: 'Investor Appropriateness Test',
    investor_categorisation: 'Investor Categorisation',
    investor_categorisation_expired: 'Investor categorisation expired',
    investor_category: 'Investor Category',
    investor_category_changed_to: 'Investor category changed to',
    investor_does_not_belong_to_any_visibility_groups: 'Investor does not belong to any visibility groups',
    investor_fees: 'Investor Fees',
    investor_id: 'Investor ID',
    investor_information: 'Investor Information',
    investor_is_currently_suspended: 'Investor is currently suspended',
    investor_is_not_authorised: 'Investor is not authorised to make investments',
    investor_name: 'Investor Name',
    investor_onboarding: 'Investor Onboarding',
    investor_registrations: 'Investor Registrations',
    investor_shareholding_type: 'Investor Shareholding Type',
    investor_structure: 'Investor Structure',
    investor_type: 'Investor Type',
    investor_type_attempts: 'Investor Type Attempts',
    investors: 'Investors',
    ip_address: 'IP Address',
    irr: 'IRR',
    is: 'Is',
    is_active: 'Is Active?',
    is_after: 'Is after',
    is_before: 'Is before',
    is_either: 'Is either',
    is_empty: 'Is empty',
    is_fund: 'Holding is for fund',
    is_less_than: 'Is less than',
    is_less_than_or_is: 'Is less than or equal to',
    is_more_than: 'Is more than',
    is_more_than_or_is: 'Is more than or equal to',
    is_not: 'Is not',
    is_not_empty: 'Is not empty',
    is_there_a_power_of_attorney_in_place: 'Is there a Power of Attorney in place?',
    is_this_an_offer_for_a_fund: 'Is this an offer for a fund?',
    is_this_client_advised: 'Is this client advised?',
    is_this_company_a_vct_fund: 'Is this company a VCT fund?',
    is_this_convertible_loan_certificated: 'Is this convertible loan certificated?',
    is_this_convertible_loan_secured: 'Is this convertible loan secured?',
    is_this_investment_offered_via_a_fund: 'Is this investment offered via a Fund?',
    is_this_investment_on_platform: 'Is this investment on platform?',
    is_this_the_primary_address: 'Is this the primary address?',
    is_this_the_primary_bank_account: 'Is this the primary bank account?',
    is_user_fca_blacklisted: 'Is this individual blacklisted by the FCA?',
    is_user_login_permitted: 'Is this individual permitted to log in?',
    is_user_politically_exposed: 'Is this individual classed as a Politically Exposed person?',
    is_user_usa_person: 'Is this individual a US person for tax reasons?',
    is_vat_included_on_annual_fee: 'Is VAT included on the adviser fee',
    is_vat_included_on_initial_fee: 'Is VAT included on the initial fee',
    is_vat_to_be_applied: 'Is VAT to be applied?',
    isin: 'ISIN',
    issued_on: 'Issued On',
    it_looks_like_were_having_some_internal_issues: "It looks like we're having some internal issues",
    it_looks_like_youre_trying_to_view_a_summary_of_holdings_which:
        "It looks like you're trying to view a summary of holdings which don't exist. Please make sure you're logged in as the correct user and that you have access to the portfolio you're trying to view.",
    item: 'Item | Items',
    items: 'Items',
    items_not_found: 'Items not found',
    items_per_page: 'Items per page',
    james_brearley: 'James Brearley',
    job_title: 'Job Title',
    json: 'JSON',
    jurisdiction: 'Jurisdiction',
    kanban: 'Kanban',
    key_document: 'Key Document',
    key_information: 'Key Information',
    kid: 'Key Information Document',
    label: 'Label',
    last_accounts_at: 'Last Accounts Date',
    last_accounts_type: 'Last Accounts Type',
    last_categorisation_date: 'Last categorisation date',
    last_edited_date: 'Last Edited Date',
    last_edited_on: 'Last Edited Date',
    last_investment_update_on: 'Last Investment Update Date',
    last_login: 'Last Login',
    last_name: 'Last Name',
    last_nav_entry_on: 'Last NAV Entry Date',
    last_nav_on: 'Last NAV Date',
    last_nav_share_price_entry_on: 'Last NAV Entry Share Price Date',
    last_saved: 'Last Saved',
    last_updated: 'Last Updated',
    last_updated_at: 'Last Updated',
    last_updated_by: 'Last Updated By',
    last_updated_date: 'Last Updated Date',
    launch_at: 'Launch Date',
    leave_it_blank_if_youre_not_yet_sure: "Leave it blank if you're not yet sure",
    legacy_user_id: 'Legacy User ID',
    library: 'Library',
    library_activity: 'Library Activity',
    library_entry: 'Library Entry',
    link_to_adviser_in_marketing_list: 'Link to adviser in marketing list',
    linkedin: 'LinkedIn',
    linkedin_url: 'LinkedIn URL',
    liquidated: 'Liquidated',
    load: 'Load',
    load_history_from_a_previous_session: 'Load history from a previous session',
    load_more: 'Load more',
    load_template: 'Load template',
    loading: 'Loading',
    loading_fee_documents_for: 'Loading fee documents for {field}',
    locked: 'Locked',
    log_back_in: 'Log back in',
    logins: 'Logins',
    logo: 'Logo',
    logout: 'Log out',
    logout_all_devices: 'Log out of all devices',
    london_stock_exchange: 'London Stock Exchange',
    lookups: 'Lookups',
    loyalty_discount: 'Loyalty Discount',
    lpa_agreement_date: 'LPA Agreement Date',
    lte: 'is less than or equal to',
    make_sure_that_the_investment_amounts_are_valid_before_submitting:
        'Make sure that the investment amounts are valid before submitting',
    make_sure_you_fit_the_description: 'Make sure you fit the description',
    manage_and_update_your_details: 'Manage and update your details',
    manage_media: 'Manage media',
    management_overview: 'Management Overview',
    manager: 'Manager',
    manager_overview: 'Manager Overview',
    mandatory_technical_and_regulatory_announcements: 'Mandatory technical and regulatory announcements',
    manual: 'Manual',
    manually_sign: 'Manually Sign',
    manually_signed: 'Manually Signed',
    market_cap: 'Market Cap',
    market_cap_recorded: 'Market Cap Recorded',
    market_cap_source: 'Market Cap Source',
    market_capitalisation: 'Market Capitalisation',
    marketing: 'Marketing',
    marketing_consent: 'Marketing Consent',
    matures_on: 'Matures On',
    maturity_price: 'Maturity Price',
    max: 'Max',
    max_adviser_facilitated: 'Max Adviser Facilitated',
    max_commission: 'Max Commission',
    max_fund_size_details: 'Offer Details',
    max_investment: 'Max Investment',
    max_items_selected: 'The maximum number of items have been selected',
    max_size: 'Max Size',
    maximum_tax_relief: 'Maximum tax relief',
    maybe_later: 'Maybe later',
    media: 'Media',
    media_adviser: 'Media Adviser',
    media_branding: 'Media Branding',
    media_format: 'Media Format',
    media_investor: 'Media Investor',
    media_library: 'Media Library',
    media_marketing: 'Media Marketing',
    media_type: 'Media Type',
    media_updated: 'Media updated',
    menu: 'Menu',
    message: 'Message',
    min: 'Min',
    min_investment: 'Min Investment',
    min_size: 'Min Size',
    minimum: 'Minimum',
    minimum_investment_amount: 'Minimum Investment Amount',
    misc: 'Misc',
    miscellaneous: 'Miscellaneous',
    moic: 'MOIC',
    money: 'Money',
    month: 'Month',
    months: 'Months',
    morningstar: 'Morningstar',
    most_recent: 'Most recent',
    move_to: 'Move to',
    multi_factor_authentication_cant:
        "Multi-factor Authentication can't be setup if the email address has not been verified or the user is suspended or inactive.",
    multi_investment: 'Multi-investment',
    multi_product: 'Multi-Product',
    multiple: 'Multiple',
    my_account: 'My Account',
    name: 'Name',
    name_of_bank: 'Name of Bank',
    national_insurance: 'National Insurance',
    national_insurance_number: 'National Insurance Number',
    nationality: 'Nationality',
    nav: 'NAV',
    nav_current_value: 'NAV Current Value',
    nav_discount: 'Discount/Premium to NAV',
    nav_discount_12m_average: 'Discount/Premium to NAV 12m Average',
    nav_frequency: 'NAV Frequency',
    nav_price: 'NAV Price',
    nav_total_return: 'NAV Total Return',
    nav_total_return_including_tax_relief: 'NAV Total Return incl Tax Relief',
    nav_total_return_including_tax_relief_irr: 'NAV Total Return incl Tax Relief IRR',
    nav_total_return_including_tax_relief_moic: 'NAV Total Return incl Tax Relief MOIC',
    nav_total_return_including_tax_relief_percentage: 'NAV Total Return incl Tax Relief %',
    nav_total_return_irr: 'NAV Total Return IRR',
    nav_total_return_moic: 'NAV Total Return MOIC',
    nav_total_return_percentage: 'NAV Total Return %',
    nav_total_return_profit_loss: 'NAV Total Return Profit/Loss',
    nav_total_return_profit_loss_including_tax_relief: 'NAV Total Return Profit/Loss incl Tax Relief',
    nav_total_return_profit_loss_percentage: 'NAV Total Return Profit/Loss %',
    nav_total_return_with_exits: 'NAV Total Return with Exits',
    negligible_value_report: 'Negligible Value Report',
    neq: 'does not equal',
    net_assets_for_these_purposes_do_not_include: 'Net assets for these purposes do not include:',
    net_cost: 'Net Cost',
    new: 'New',
    new_investments_this_month: 'New investments this month',
    new_investments_this_year: 'New investments this year',
    new_registrations_advised: 'New Registrations Advised',
    news: 'News',
    newsletters: 'Newsletters',
    next: 'Next',
    next_allotment: 'Next Allotment',
    next_allotment_at: 'Next Allotment Date',
    next_deadline_date: 'Next Deadline Date',
    next_event: 'Next Event',
    next_page: 'Next page',
    next_scheduled_categorisation_date: 'Next scheduled categorisation date',
    no: 'No',
    no_additional_information: 'No additional information',
    no_continue: 'No, continue',
    no_discard_and_start_over: 'No, discard and start over',
    no_downloads: 'Sorry, there are no downloads available at the moment',
    no_holdings_found: 'No holdings found',
    no_information_provided: 'No Information Provided',
    no_items_found: 'No items found',
    no_preview_available: 'No preview available',
    no_results_for: 'No results for',
    no_results_found: 'No results found',
    no_this_relationship_is_execution_only: 'No, this relationship is execution only',
    nominal_value: 'Nominal Value',
    nominee: 'Nominee',
    nominees: 'Nominees',
    non_advised: 'Non-Advised',
    non_platform_transactions: 'Non platform transactions',
    none: 'None',
    none_of_these_categories_describe_me: 'None of these categories describe me',
    not_contains: 'does not contain',
    not_found: 'not found',
    not_in: 'Not in',
    not_sent: 'Not sent',
    not_set: 'Not set',
    not_signed: 'Not signed',
    not_sorted: 'Not sorted',
    not_verified: 'Not verified',
    not_viewed: 'Not viewed',
    note: 'Note',
    notes: 'Notes',
    notifications: 'Notifications',
    null: 'Null',
    number: 'Number',
    number_of_investments: 'No. of Investments',
    number_of_shares: 'Number of Shares',
    number_of_shares_in_issue: 'Number of Shares in Issue',
    of: 'of',
    offer: 'Offer',
    offer_details: 'Offer Details',
    offer_has_no_open_products: 'This offer currently has no open products',
    offer_or_product: 'Offer or Product',
    offer_price: 'Offer Price',
    offer_share_price: 'Offer Share Price',
    offer_size: 'Offer Size',
    offer_status: 'Offer Status',
    offer_type: 'Offer Type',
    offers: 'Offers',
    offline_app_form: 'Offline App Form',
    ok: 'OK',
    on_behalf_of: 'on behalf of',
    on_boarding_status: 'Onboarding Status',
    on_platform: 'On Platform',
    onboarding: 'Onboarding',
    onboarding_status: 'Onboarding Status',
    open_at: 'Open Date',
    open_date: 'Open Date',
    opened: 'Opened',
    operating_expenses: 'Operating Expenses',
    operations: 'Operations',
    operator: 'Operator',
    options: 'Options',
    or: 'or',
    order_execution_policy: 'Order Execution Policy',
    organisation: 'Organisation',
    organisation_details: 'Organisation Details',
    organisation_status: 'Organisation Status',
    organisation_type: 'Organisation Type',
    organisations: 'Organisations',
    other: 'Other',
    other_platform_transactions: 'Other platform transactions',
    other_updates: 'Other updates',
    our_technical_team_has_been_notified_of_the_problem:
        "Our technical team has been notified but if you'd like to provide more information to help resolve the issue please tell us what happened",
    over_allotment: 'Over-Allotment',
    over_allotment_actual: 'Over-Allotment Actual',
    over_allotment_available: 'Over-Allotment Available',
    over_allotment_proposed: 'Over-Allotment Proposed',
    over_allotting: 'Over-Allotting',
    overall: 'Overall',
    overall_offer_size: 'Overall Offer Size',
    overview: 'Overview',
    p_per_share: 'p per share',
    p1_client_code: 'P1 Client Code',
    page: 'Page',
    page_not_found: 'Page Not Found',
    page_number: 'Page Number',
    panel: 'Panel',
    panel_name: 'Panel Name',
    panel_offers: 'Panel Offers',
    paper_signature: 'Paper Signature',
    parent: 'Parent',
    parent_adviser: 'Parent Adviser',
    parent_uuid: 'Parent UUID',
    pdf_cover: 'PDF Cover',
    pdf_disclaimer_0:
        'This communication has been issued in the United Kingdom by EIS Platforms Limited. EIS Platforms Limited (FRN: 694945) is an appointed representative of Sapphire Capital Partners LLP (FRN:565716) which is authorised and regulated by the Financial Conduct Authority in the UK. Participation by persons within and without the UK may be restricted by law and investors will only be permitted to participate once EIS Platforms Limited is satisfied that such participation is permitted in the relevant jurisdiction.',
    pdf_disclaimer_1:
        'The contents of this document should not be regarded as constituting advice. Prospective investors should take appropriate independent investment advice on these matters.',
    pdf_disclaimer_2:
        "Investing in start-ups and early stage businesses involves risks, including illiquidity (the inability to sell assets quickly or without substantial loss in value), lack of dividends, loss of investment and dilution, and it should be done only as part of a diversified portfolio. EIS Platforms Limited is targeted exclusively at investors who are sufficiently sophisticated to understand these risks and make their own investment decisions. You will only be able to invest via EIS Platforms Limited once you are registered as sufficiently sophisticated. Your capital is at risk if you invest. Tax treatment is dependent on an individuals' circumstances and may be subject to change in the future.",
    pdf_disclaimer_3:
        'EIS Platforms Limited has taken all reasonable steps to ensure that the factual content contained in this document is accurate. The EIS pitches are not intended to be a promotion of any individual investment opportunity, and the summary information provided about investment opportunities is intended solely to demonstrate the types of investments available through EIS Platforms Limited . Any investment decision should be made solely on the basis of the full listing for that particular investment opportunity. Full listings are available to authorised investors only.',
    pdf_disclaimer_4:
        'Some material included in this document is derived from public or third party sources and EIS Platforms Limited disclaims all liability for any errors or misrepresentations which any such inclusions may contain.',
    pending: 'Pending',
    pending_allotment: 'Pending Allotment',
    pending_allotments_must_be_allotted_before_progressing: 'Pending allotments must be allotted before progressing',
    percent: 'Percent',
    percentage: 'Percentage',
    percentage_of_investment: '% of investment',
    percentage_of_overall_portfolio: '% of overall portfolio',
    performance: 'Performance',
    performance_fee: 'Performance Fee',
    performance_hurdle: 'Performance Hurdle',
    personal: 'Personal',
    phone_number: 'Phone Number',
    phone_number_type: 'Phone Number Type',
    phone_numbers: 'Phone Numbers',
    pipeline: 'Pipeline',
    plan: 'Plan',
    platform: 'Platform',
    platform_activity: 'Platform Activity',
    platform_cash_balance: 'Platform Cash Balance',
    platform_fees: 'Platform Fees',
    please_add_in_supply_details_of_all_tax_residencies:
        'Please add in supply details of all tax residencies, including your UK Unique tax reference number (UTR).',
    please_complete_your_profile_before_downloading_the_application:
        'Please complete your profile before downloading the application form',
    please_make_sure_you_have_read_the_terms: 'Please make sure you have read the terms and conditions',
    please_note_that_if_you_cancel_this_request:
        'Please note that if you cancel this request, the document will not be exported.',
    please_note_there_may_be_a_requirement_for_some_additional_info:
        'Please note, there may be a requirement for some additional information and identification checks. If so we will contact you and your financial intermediary by email. If you have questions please contact us at support@growthinvest.com or on 0300 303 0037.',
    please_provide_more_detail: 'Please provide more detail',
    please_read_carefully_and_answer_the_following_questions:
        'Please read carefully and answer the following questions to help us confirm your investment experience. If you provide any incorrect answers you will not be able to proceed directly with your online application, and our client services team will be in touch to help you with the next steps.',
    please_select_a_company: 'Please select a company',
    please_select_a_company_or_enter_company_info_manually: 'Please select a company or enter company info manually',
    please_select_an_account: 'Please select an account',
    please_select_an_adviser: 'Please select an adviser',
    please_select_an_item_from_the_menu: 'Please select an item from the menu',
    please_select_your_financial_adviser: 'Please select your financial intermediary from the following companies:',
    please_specify: 'Please specify',
    please_try_again: 'Please try again',
    please_try_again_later: 'Please try again later',
    please_update_the_clients_address_history: 'Please update the clients address history to continue.',
    please_update_your_address_history: 'Please update your address history to continue.',
    please_wait: 'Please wait',
    please_wait_while_we_make_this_image_available: 'Please wait while we make this image available',
    pledge_balance: 'Pledge Balance',
    pledged: 'Pledged',
    politically_exposed: 'Politically exposed person',
    portal_selection: 'Portal Selection',
    portfolio: 'Portfolio',
    portfolio_detail: 'Portfolio Detail',
    portfolio_diversity: 'Portfolio Diversity',
    portfolio_overview: 'Portfolio Overview',
    portfolio_summary: 'Portfolio Summary',
    portfolio_value: 'Portfolio Value',
    position_reconciliation: 'Position Reconciliation',
    post_investment: 'Post-investment',
    post_money_valuation: 'Post Money Valuation',
    postcode: 'Post Code',
    power_of_attorney: 'Power of Attorney',
    powered_by: 'Powered by',
    pre_investment: 'Pre-investment',
    pre_money_valuation: 'Pre Money Valuation',
    prefilled: 'Prefilled',
    prefilled_app_form: 'Prefilled App Form',
    prepare: 'Prepare',
    preview: 'Preview',
    preview_could_not_be_created_for_this_file_type: 'Preview could not be created for this file type',
    previous: 'Previous',
    previous_company_name: 'Previous Company Name',
    previous_company_names: 'Previous Company Names',
    previous_financial_year: 'Previous Financial Year',
    previous_page: 'Previous page',
    pricing_update_request_submitted: 'Pricing update request submitted',
    primary: 'Primary',
    primary_contact: 'Primary Contact',
    primary_contact_email: 'Primary Contact Email',
    primary_contact_name: 'Primary Contact Name',
    primary_contact_phone_number: 'Primary Contact Phone Number',
    primary_exchange: 'Primary Exchange',
    primary_user: 'Primary User',
    privacy_agreement: 'Privacy Agreement',
    privacy_policy: 'Privacy Policy',
    private_market_investments: 'Private Market Investments',
    proceed: 'Proceed',
    proceeding_will_cancel_this_digital_application:
        'Proceeding will cancel the digital application and allow changes to the proposal. You can opt to switch to a paper signature, send a new digital application, or abort the proposal altogether once the digital application is cancelled.',
    proceeding_will_logout_all_devices:
        "Proceeding will log you out of all devices, incl the device you're currently using. Once you've logged out, you will get redirected to the login page.",
    proceeding_will_mark_this_proposal_as_permanently_cancelled:
        'Proceeding will mark this proposal as permanently cancelled. Are you sure you want to do this?',
    proceeding_will_permanently_delete: 'Proceeding will permanently delete',
    proceeding_will_resend_this_digital_application:
        'Proceeding will send a new digital application to the signatories. Make sure that the proposal has been thoroughly updated to meet any requirements before moving forward.',
    processor_client_code: 'Processor Client Code',
    product: 'Product',
    product_details: 'Product Details',
    product_fees: 'Product Fees',
    product_id: 'Product ID',
    product_name: 'Product Name',
    product_overview: 'Product Overview',
    product_provider: 'Product Provider',
    product_status: 'Product Status',
    product_type: 'Product Type',
    products: 'Products',
    products_and_offers: 'Products and Offers',
    professional_advice: 'Professional Advice',
    profile: 'Profile',
    profile_complete: 'Profile Complete',
    profile_status: 'Profile Status',
    profit_loss: 'Profit/Loss',
    profit_loss_including_tax_relief: 'Profit/Loss incl Tax Relief',
    profit_loss_percentage: 'Profit/Loss %',
    profitability_forecast: 'Profitability Forecast',
    progress: 'Progress',
    property: 'Property | Properties',
    proposal: 'Proposal',
    proposal_aborted: 'Proposal aborted',
    proposal_accepted: 'Proposal accepted',
    proposal_amount_updated: 'Proposal amount updated',
    proposal_completed: 'Proposal completed',
    proposal_created: 'Proposal created',
    proposal_redistribution: 'Proposal redistribution',
    proposal_rejected: 'Proposal rejected',
    proposal_status_updated: 'Proposal status updated',
    proposal_updated: 'Proposal updated',
    proposal_workflow_activity: 'Proposal workflow activity',
    proposals: 'Proposals',
    proposals_created_successfully: 'Proposals created successfully',
    propose: 'Propose',
    proposed: 'Proposed',
    proposed_amount: 'Proposed Amount',
    proposed_amounts: 'Proposed amounts',
    proposed_at: 'Date Proposed',
    proposed_investment: 'Proposed Investment',
    proposed_investments: 'Proposed Investments',
    proposing: 'Proposing',
    prospectus: 'Prospectus',
    provide_nominee_name_or_reference:
        'If you are a beneficial holder (within a Nominee) please provide the nominee name or reference',
    provide_shareholder_reference: 'If you are a registered holder (Direct) please provide the shareholder reference',
    provider: 'Provider',
    provider_overview: 'Provider Overview',
    providers: 'Providers',
    provides_tax_relief_on_adviser_fees: 'Provides Tax Relief on Adviser Fees',
    public_adviser: 'Public Adviser',
    publish: 'Publish',
    published: 'Published',
    publishing: 'Publishing',
    purchase_cost: 'Purchase Cost',
    purchased_on: 'Purchase Date',
    quantity: 'Quantity',
    quarantined: 'Quarantined',
    questionnaire: 'Questionnaire',
    questions: 'Questions',
    raise_amount: 'Raise Amount',
    raised: 'Raised',
    ratio: 'Ratio',
    read: 'Read',
    read_more: 'Read more',
    realisations: 'Realisations',
    rebalance: 'Rebalance',
    recategorisation_is_currently_unavailable: 'Recategorisation is currently unavailable',
    recategorisation_is_required_now: 'Recategorisation is required now',
    recategorisation_was_required: 'Recategorisation was required',
    recategorisation_will_be_required: 'Recategorisation will be required',
    recategorise: 'Recategorise',
    received_on: 'Received Date',
    receiving_agent: 'Receiving Agent',
    receiving_agents: 'Receiving Agents',
    reconciled: 'Reconciled',
    reconciled_at: 'Reconciled Date',
    reconciled_balance: 'Reconciled Balance',
    reconciled_balance_updated_at: 'Reconciled Balance Updated Date',
    reconciliation: 'Reconciliation',
    record_at: 'Record Date',
    redemption_premium: 'Redemption Premium',
    redistribution_algorithm: 'Redistribution Options',
    redo: 'Redo',
    reference: 'Reference',
    reference_number: 'Reference Number',
    region: 'Region',
    registered_date: 'Date Registered',
    registrar: 'Registrar',
    registrars: 'Registrars',
    registration: 'Registration',
    registration_error: 'Registration Error',
    registration_events: 'Registration Events',
    registration_status: 'Registration Status',
    registrations: 'Registrations',
    regulatory: 'Regulatory',
    regulatory_updates: 'Regulatory updates',
    reject: 'Reject',
    reject_proposal: 'Reject proposal',
    related_organisations: 'Related Organisations',
    relationship: 'Relationship',
    reload: 'Reload',
    remote_application: 'Remote Application',
    remote_application_builder: 'Remote Application Builder',
    remote_applications: 'Remote Applications',
    remote_id: 'Remote ID',
    remove: 'Remove',
    remove_as_primary: 'Remove as the primary',
    remove_filters: 'Remove Filters',
    remove_this_item: 'Remove this item',
    removed: 'Removed',
    replace: 'Replace',
    replace_template: 'Replace template',
    reports: 'Reports',
    request_additional_information: 'Request Additional Information',
    request_detailed_fee_illustration: 'Request detailed fee illustration',
    request_fee_illustration: 'Request Fee Illustration',
    request_information: 'Request Information',
    required: 'Required',
    required_signatory: 'Required Signatory',
    resend_application: 'Resend Application',
    reset: 'Reset',
    response_not_recognised: 'Response not recognised',
    restrict_to_investor_categories: 'Restrict to Investor Categories',
    restricted_offer_panels: 'Restricted Offer Panels',
    restricted_panel: 'Restricted Panel',
    restricted_panels: 'Restricted Panels',
    result: 'Result',
    results: 'Results',
    resync_pricing: 'Resync Pricing',
    retry: 'Retry',
    revaluation_on: 'Re Valuation Date',
    revenue_forecast: 'Revenue Forecast',
    ri: 'RI',
    risk_summary: 'Risk Summary',
    risk_warning: 'Risk Warning',
    risk_warning_text:
        "Don't invest unless you're prepared to lose all your money. These are high-risk investments and you are unlikely to be protected if something goes wrong.",
    role: 'Role',
    row: 'Row',
    sale_proceeds: 'Sale Proceeds',
    sales: 'Sales',
    salesforce_id: 'Saleforce ID',
    salesforce_last_sync: 'Salesforce Last Sync',
    sample_companies: 'Sample Companies',
    sample_portfolio_companies: 'Sample Portfolio Companies',
    save: 'Save',
    save_and_continue: 'Save and continue',
    save_changes: 'Save Changes',
    save_changes_before_continuing: 'Save changes before continuing?',
    save_changes_to_the_fields_youre_editing_before_submitting:
        'Save changes to the fields you’re editing before submitting',
    save_template: 'Save template',
    saving: 'Saving',
    scanning_error: 'Scanning error',
    scanning_file: 'Scanning file',
    scope: 'Scope',
    sdr_label: 'SDR Label',
    search: 'Search',
    search_by: 'Search by',
    search_for_an_address: 'Search for an address',
    search_or_enter_the_address_details: 'Search or enter the address details',
    sector: 'Sector',
    security: 'Security',
    security_options_will_become_available_when_users_are_added_to_this_organisation:
        'Security options will become available when users are added to this organisation',
    sedol: 'SEDOL',
    see_list_of_all_offers_available: 'See the list of all current offers available for Investment',
    see_list_of_all_platform_users: 'See the list of all users on the current platform',
    see_list_of_all_your_clients: 'See the list of all your clients on the current platform',
    see_tabs_of_all_display_sections: 'See the tabs of all display sections',
    select: 'Select',
    select_a_client: 'Select a client',
    select_a_client_from_the_list_of_all_clients: 'Select a client from the list of all clients',
    select_a_country: 'Select a country',
    select_a_shortlist: 'Select a shortlist',
    select_a_shortlist_to_add_this_offer_to: 'Select a shortlist to add this offer to',
    select_one_of_the_following: 'Select one of the following',
    select_the_category_thats_the_closest_match_for_your_situation:
        "Select the category that's the closest match for your situation to see more information that'll help you confirm whether it's the right one.",
    select_the_columns_you_would_like_to_display: 'Select the columns you would like to display',
    select_the_company: 'Select the company',
    select_the_company_portal: 'Select the company portal you wish to join',
    select_this_category: 'Select this category',
    selected: 'Selected',
    selecting_a_dynamic_value_will_replace_the_content_with_the_result_of_the_calculation:
        'Selecting a dynamic value will replace the content with the result of the calculation',
    send_message: 'Send message',
    send_report: 'Send report',
    send_request: 'Send request',
    send_verification_email: 'Send verification email',
    sending: 'Sending',
    sent: 'Sent',
    sequence: 'Sequence',
    session_expired: 'Session Expired',
    set: 'Set',
    set_as_primary: 'Set as the primary',
    set_to_suggested_amount: 'Set to suggested amount',
    setting_this_holding_to_unverified_will_also_mark_the_associated_tranche:
        'Setting this holding to unverified will also mark the associated tranche as unverified. You will need to manually verify the tranche.',
    setting_this_tranche_to_verified_will_also_mark_all_holdings:
        'Setting this tranche to verified will also mark all holdings related to this tranche as verified.',
    settings: 'Settings',
    settlement: 'Settlement',
    setup_mfa: 'Setup MFA',
    share: 'Share',
    share_certificate_received_on: 'Share Certificate Received Date',
    share_class: 'Share Class',
    share_class_offered: 'Share Class Offered',
    share_classes: 'Share Classes',
    share_count: 'Share Count',
    share_issue_nav: 'Share Issue NAV',
    share_issue_on: 'Share Issue Date',
    share_issue_price: 'Share Issue Price',
    share_nominal_value: 'Share Nominal Value',
    share_price: 'Share Price',
    share_price_for_current_investment_round: 'Share Price for Current Investment Round',
    share_price_return_percentage_1y: 'Share Price Total Return % - 1 year',
    share_price_return_percentage_5y: 'Share Price Total Return % - 5 year',
    share_price_return_percentage_10y: 'Share Price Total Return % - 10 year',
    share_price_total_return_1y: 'Share Price Total Return % - 1 year',
    share_price_total_return_5y: 'Share Price Total Return % - 5 year',
    share_price_total_return_10y: 'Share Price Total Return % - 10 year',
    share_qty: 'Share Quantity',
    share_quantity: 'No. Shares',
    shares_awaiting_allocation: 'Shares Awaiting Allocation',
    shares_issued: 'Shares Issued',
    shares_offered: 'Shares Offered',
    shortlist: 'Shortlist',
    shortlist_activity: 'Shortlist activity',
    shortlist_fees: 'Shortlist Fees',
    shortlist_name: 'Shortlist name',
    shortlist_value: 'Shortlist value',
    shortlisted: 'Shortlisted',
    shortlists: 'Shortlists',
    shortlists_created: 'Shortlists created',
    show: 'Show',
    show_detail: 'Show Detail',
    show_hide: 'Show/Hide',
    show_hide_columns: 'Show/Hide Columns',
    show_table: 'Show Table',
    sic_codes: 'SIC Codes',
    signatories: 'Signatories',
    signatory: 'Signatory',
    signatory_on_behalf_of: 'Signatory on behalf of',
    signatory_on_behalf_of_investor: 'Signatory on behalf of Investor',
    signature_key: 'Signature Key',
    signed: 'Signed',
    signed_off: 'Signed Off',
    signed_off_by: 'Signed Off By',
    signoff_date: 'Sign-off Date',
    single_company: 'Single Company',
    single_company_investments: 'Single Company Investments',
    something_went_wrong: 'Something went wrong',
    sort_code: 'Sort Code',
    sorted: 'Sorted',
    source: 'Source',
    source_ip_address: 'Source IP Address',
    start_allotment_process: 'Start Allotment Process',
    start_date: 'Start Date',
    start_typing_the_company_name: 'Start typing the company name to search for a company',
    start_typing_to_search: 'Start typing to search',
    start_typing_your_postcode: 'Start typing your post code',
    starts_with: 'Starts with',
    statistics: 'Statistics',
    status: 'Status',
    stay_logged_in: 'Stay logged in',
    street_address: 'Street Address',
    strike_price: 'Strike Price',
    structure: 'Structure',
    style: 'Style',
    submit: 'Submit',
    submitted: 'Submitted',
    submitted_at: 'Submitted Date',
    submitted_by: 'Submitted By',
    submitted_by_id: 'Submitted By',
    submitting: 'Submitting',
    subscription_notice: 'Subscription Notice',
    success: 'Success',
    successfully: 'Successfully',
    summary: 'Summary',
    summary_by_tax_status: 'Summary by Tax Status',
    summary_of_private_market_investments: 'Summary of private market investments',
    summary_of_tax_efficient_investments: 'Summary of tax efficient investments',
    sustainability: 'Sustainability',
    swap_to_calculate_the_amount_from_a_percentage: 'Swap to calculate the fixed amount from a percentage',
    swap_to_enter_a_fixed_amount: 'Swap to enter a fixed amount',
    switch_account: 'Switch Account',
    switch_adviser: 'Switch Adviser',
    switch_client: 'Switch Client',
    switch_investor: 'Switch Investor',
    switch_off_to_exclude_closed_offers: 'Switch off to exclude closed offers',
    switch_off_to_show_off_panel_products: 'Switch off to show off-panel products',
    switch_off_to_show_portfolio_entries_for_the_current_account:
        'Switch off to show portfolio entries for the current account',
    switch_off_to_show_price_based_values: 'Switch off to show price based values',
    switch_on_to_show_all_offers: 'Switch on to show all offers',
    switch_on_to_show_closed_offers: 'Switch on to include closed offers',
    switch_on_to_show_nav_based_values: 'Switch on to show NAV based values',
    switch_on_to_show_portfolio_entries_across_all_accounts: 'Switch on to show portfolio entries across all accounts',
    switch_on_to_show_your_approved_panel: 'Switch on to show your approved panel',
    switch_tax_year: 'Switch Tax Year',
    switch_user: 'Switch User',
    system: 'System',
    system_error: 'System Error',
    system_settings: 'System Settings',
    table: 'Table',
    table_of_contents: 'Table of Contents',
    take_2_mins_to_learn_more: 'Take 2 mins to learn more',
    take_your_time_to_specify_the_correct_category:
        "Take your time to specify the correct category - it'll affect the investment opportunities you see on the platform.",
    target: 'Target',
    target_annual_dividend: 'Target Annual Dividend',
    target_dividend: 'Target Dividend',
    target_dividend_description: 'Target Dividend Description',
    target_dividend_start_on: 'Target Dividend Start On',
    target_dividend_type: 'Target Dividend Type',
    target_gross_irr: 'Target Gross IRR',
    target_gross_multiple: 'Target Gross Multiple',
    target_irr: 'Target IRR',
    target_raise: 'Target Raise',
    target_return: 'Target Return',
    target_return_details: 'Target Return Details',
    tax_cert_destination: 'Tax Cert Destination',
    tax_certificate: 'Tax Certificate',
    tax_certificate_date: 'Tax Certificate Date',
    tax_certificate_destination: 'Tax Certificate Destination',
    tax_certificates: 'Tax certificates',
    tax_date: 'Tax Date',
    tax_destination: 'Tax Destination',
    tax_information: 'Tax Information',
    tax_ref_number: 'Tax Ref Number',
    tax_reference_number: 'Tax Reference Number',
    tax_reference_numbers: 'Tax Reference Numbers',
    tax_relief: 'Tax Relief',
    tax_relief_available_on_initial_adviser_fee: 'Tax relief available on initial adviser fee',
    tax_relief_certificate_events: 'Tax Relief Certificate Events',
    tax_relief_certificates: 'Tax relief certificates',
    tax_relief_for_facilitated_fees: 'Tax relief for Facilitated Fees',
    tax_relief_on_100_percent_deployment: 'Total tax relief on 100% deployment',
    tax_relief_on_adviser_fee: 'Tax Relief on Adviser Fee',
    tax_residency: 'Tax Residency',
    tax_status: 'Tax Status',
    tax_year: 'Tax Year',
    tax_year_report: 'Tax Year Report',
    tax_years: 'Tax Years',
    taxonomy: 'Taxonomy',
    team_details: 'Team Details',
    tell_us_if_youre_receiving_professional_advice:
        "Tell us if you're receiving professional advice on the investments you're looking to make on the platform, or if you've been recommended to sign up by a financial adviser.",
    template_label: 'Template Label',
    terms: 'Terms',
    terms_and_conditions: 'Terms and Conditions',
    terms_status: 'Terms Status',
    text: 'Text',
    text_not_configured: 'Text not configured',
    text_size: 'Text Size',
    thank_you: 'Thank You',
    thank_you_for_completing_your_categorisation: 'Thank you for completing your categorisation',
    thank_you_for_submitting_your_application_for_an_investment_account:
        'Thank you for applying for a GrowthInvest investment account. Your application will be reviewed by the GrowthInvest client services team and sent for signature to you and your financial intermediary (if applicable).',
    the_application_form_has_been_sent_to_the_signatory:
        'The application form has been sent to the signatory for digital signature',
    the_company_i_use_isnt_listed_here: "The company I use isn't listed here",
    the_investor_categorisation_expires: 'The investor categorisation expires',
    the_offer_is_not_configured_for_auto_generated_application_forms:
        'The offer associated with this proposal is not configured for automatically generated application forms. Please download and use one of the static application forms provided on the Offer page, or contact the Client Services team for assistance.',
    the_proposals_page: 'the proposals page',
    the_system_encountered_a_problem_when_trying_to_make_this_file_available:
        'The system encountered a problem when trying to make this file available',
    the_url_of_the_file_published_elsewhere:
        'The URL of the file published elsewhere. Note that unlike files which are uploaded to the platform, there is no security on external URLs and the document is fully accessible to any person who has this URL.',
    there_are_no_holdings_pending_allotment: 'There are no holdings pending allotment.',
    there_are_no_open_positions: 'There are no open positions.',
    there_is_no_file_attached_to_this_media_item: 'There is no file attached to this media item',
    there_was_an_unexpected_error: 'We encountered an unexpected error.',
    theres_been_an_error_with_the_registration_process:
        "There's been an error with the registration process. Our operations team will be in touch to complete your application.",
    these_notifications_are_a_required_part_of_the_service:
        'These notifications are a required part of the service and you can’t opt out of them',
    third_party_access: 'Third-Party Access',
    this_export_takes_a_significant_amount_of_time:
        'This export takes a significant amount of time and generates a large amount of data.',
    this_includes_deployments_and_transactions_within_funds:
        'This includes deployments and transactions within funds that Growthinvest are made aware of',
    this_investor: 'this investor',
    this_is_not_the_primary: 'This is not the primary',
    this_is_the_primary: 'This is the primary',
    this_is_the_reported_tax_status_of_an_investment:
        'This is the reported tax status of an investment and not of any fund the investment may be a part of',
    this_isnt_me: "This isn't me",
    this_month: 'this month',
    this_portfolio_contains_unverified_holdings:
        'This portfolio contains unverified holdings. We are working with the relevant parties to verify this information and once we do so, the data on those holdings may change.',
    this_product_has_closed_and_the_amount_has_been_redistributed:
        'This product has closed, amounts proposed in this product have been redistributed to other products which remain open.',
    this_product_has_closed_and_the_amount_needs_redistribution:
        'This product has closed, amounts proposed in this product should be redistributed to other products which remain open.',
    this_product_is_no_longer_available_to_invest_in: 'This product is no longer available to invest in',
    this_year: 'this year',
    ticker: 'Ticker',
    ticking_this_box_will_send_account_verification_email_to_client:
        'Ticking this box will send an account verification email to the client, allowing them to activate their account and login.',
    time: 'Time',
    title: 'Title',
    to: 'to',
    to_allocate: 'to allocate',
    to_process_the_request_you_can_contact_us_with_the_details_of_the_request:
        "To process the request you can contact clientservices@growthinvest.com with the details of your request and we'll process it for you",
    today: 'Today',
    todo: 'Todo',
    todo_items_awaiting_completion: 'Todo items awaiting completion',
    too_many_shares_allocated:
        'Too many shares allocated, please reduce the number of shares allocated or increase the total number of shares',
    top_10: 'Top 10',
    top_holdings: 'Top Holdings',
    total: 'Total',
    total_amount: 'Total Amount',
    total_amount_including_fees: 'Total amount incl fees',
    total_assets: 'Total Assets',
    total_balance: 'Total Balance',
    total_commitment: 'Total Commitment',
    total_dividends: 'Total Dividends',
    total_exits: 'Total Exits',
    total_fee: 'Total Fee',
    total_including_fees: 'Total incl Fees',
    total_invested: 'Total Invested',
    total_investment: 'Total Investment',
    total_investments: 'No. of Investments',
    total_offers: 'Total Offers',
    total_pledges: 'No. of Pledges',
    total_portfolio_value: 'Total portfolio value',
    total_proposed: 'Total Proposed',
    total_proposed_amount: 'Total Proposed Amount',
    total_return: 'Total Return',
    total_return_including_tax_relief: 'Total Return incl Tax Relief',
    total_return_including_tax_relief_irr: 'Total Return incl Tax Relief IRR',
    total_return_including_tax_relief_moic: 'Total Return incl Tax Relief MOIC',
    total_return_including_tax_relief_percentage: 'Total Return incl Tax Relief %',
    total_return_irr: 'Total Return IRR',
    total_return_moic: 'Total Return MOIC',
    total_return_percentage: 'Total Return %',
    total_return_profit_loss: 'Total Return Profit/Loss',
    total_return_profit_loss_including_tax_relief: 'Total Return Profit/Loss incl Tax Relief',
    total_return_with_exits: 'Total Return with Exits',
    total_shares: 'Total Shares',
    total_shortlisted: 'Total Shortlisted',
    total_subscription: 'Total subscription',
    total_tax_relief: 'Total tax relief',
    total_value: 'Total Value',
    totals: 'Totals',
    town: 'Town',
    trading_name: 'Trading Name',
    tranche: 'Tranche',
    tranche_name: 'Tranche Name',
    tranches: 'Tranches',
    transaction: 'Transaction',
    transaction_date: 'Transaction Date',
    transaction_events: 'Transaction Events',
    transaction_reconciliation: 'Transaction Reconciliation',
    transaction_status: 'Transaction Status',
    transaction_type: 'Transaction Type',
    transactions: 'Transactions',
    true: 'True',
    try_searching_for_something_else: 'Try searching for something else.',
    twitter: 'Twitter',
    twitter_user: 'Twitter User',
    type: 'Type',
    type_the_name_of_the_company_to_search: 'Type the name of the company to search',
    unable_to_fulfill_request:
        "We're unable to fulfill your request. Rest assured we have been notified and are looking into the issue. Thank you for your patience.",
    unavailable: 'Unavailable',
    undefined: 'Undefined',
    undeployed_funds: 'Undeployed funds',
    undo: 'Undo',
    undrawn_capital: 'Undrawn Capital',
    uninvested_capital: 'Uninvested Capital',
    unrealised_gain_loss_on_investments: 'Unrealised Gain/Loss on Investments',
    unused: 'Unused',
    update_all_from_remote: 'Update all from remote',
    update_successful: 'Update successful',
    updated: 'Updated',
    updating_all_from_remote: 'Updating all from remote',
    upload_and_manage_documents: 'Upload and manage documents',
    upload_media: 'Upload Media',
    url: 'URL',
    us_person: 'US person',
    use_more_generic_search_terms: 'Use more generic search terms',
    use_of_funds: 'Use of Funds',
    user: 'User',
    user_email: 'User Email',
    user_management: 'User Management',
    user_organisation: 'User Organisation',
    user_profile: 'User Profile',
    user_role: 'User Role',
    user_roles: 'User Roles',
    user_status: 'User Status',
    user_tax_certificate_destination: 'User Tax Certificate Destination',
    user_updated: 'User updated',
    users: 'Users',
    validation_error: 'Validation Error',
    valuation: 'Valuation',
    valuation_on: 'Valuation Date',
    valuation_source: 'Valuation Source',
    valuation_type: 'Valuation Type',
    valuations: 'Valuations',
    value: 'Value',
    value_at: 'Value Date',
    value_date: 'Value Date',
    vat: 'VAT',
    vat_included: 'VAT Included',
    vct: 'VCT',
    vct_and_single_company: 'VCT and Single Company',
    vct_fund_status: 'VCT Fund Status',
    venture_capital_trusts: 'Venture Capital Trusts',
    verification_email: 'Verification email',
    verified: 'Verified',
    verify: 'Verify',
    verifying: 'Verifying',
    verticals: 'Verticals',
    via_fund: 'Via Fund',
    view: 'View',
    view_and_download_reports: 'View and download reports',
    view_as_cards: 'View as Cards',
    view_as_kanban: 'View as Kanban',
    view_as_table: 'View as Table',
    view_fee_summary: 'View fee summary',
    view_offer: 'View offer',
    viewed: 'Viewed',
    visibility_group: 'Visibility Group',
    visibility_group_created: 'Visibility group created',
    visibility_group_updated: 'Visibility group updated',
    visibility_groups: 'Visibility Groups',
    visibility_warning: 'Visibility Warning',
    visible_to: 'Visible to {to}',
    visit: 'Visit',
    we_are_awaiting_further_verification_of_some_holdings:
        'We are awaiting further verification of some holdings from the Provider. This could mean that some values may change.',
    we_are_awaiting_further_verification_of_this_holding:
        'We are awaiting further verification of this holding from the Provider. This could mean that some values may change.',
    we_cant_find_the_thing_youre_looking_for: "We can't find the thing you're looking for",
    we_couldnt_find_any_activity: "We couldn't find any activity",
    we_couldnt_find_any_clients: "We couldn't find any clients",
    we_couldnt_find_any_companies_matching_the_details_you_entered:
        "We couldn't find any companies matching the details you entered",
    we_couldnt_find_any_holdings_in_this_group: "We couldn't find any holdings in this group",
    we_couldnt_find_any_media_in_this_library: "We couldn't find any media in this library",
    we_couldnt_find_any_users: "We couldn't find any users",
    we_encountered_a_problem_when_trying_to_make_this_file_available:
        'The system encountered a problem when trying to make this file available. Our support team has been notified and are working on the problem. Please try again later.',
    we_encountered_a_problem_with_this_file_and_it_has_been_quarantined:
        'We encountered a problem with this file and it has been quarantined. Our support team has been notified and are investigating. Please try again later.',
    we_found_multiple_companies_matching_the_details_you_entered:
        'We found multiple companies matching the details you entered',
    we_found_one_company_matching_the_details_you_entered: 'We found one company matching the details you entered',
    we_may_have_to_adjust_this_amount_to_cater_for_rounding:
        'We may have to adjust this investment amount to cater for rounding and fund or provider requirements.',
    website: 'Website',
    welcome_to_the_platform: 'Welcome to the platform',
    well_use_this_to_generate_a_bespoke_fee_illustration: "We'll use this to generate a bespoke fee illustration",
    were_required_to_revalidate_this_information_with_you_every_12_months:
        'We’re required to re-validate this information with you every 12 months. This process will automatically trigger when your categorisation expires, but you can recategorise at any time if your circumstances change by using the Recategorise button in your investor profile pages. A copy of your results can also be downloaded from your profile to provide to third parties.',
    weve_encountered_a_problem: "We've encountered a problem",
    weve_encountered_a_problem_displaying_the_page_you_requested:
        "We've encountered a problem displaying the page you requested",
    weve_encountered_a_problem_processing_the_request_and_your_changes_have_not_been_saved:
        "We've encountered a problem processing the request and your changes have not been saved",
    what_are_non_readily_realisable_securities: 'What are non-readily realisable securities?',
    what_happened: 'What happened?',
    what_type_of_investor_are_you: 'What type of investor are you?',
    whats_the_name_of_the_company_providing_you_with_financial_advice:
        "What's the name of the company providing you with advice or other services?",
    whats_the_name_of_the_person_you_deal_with: "What's the name of the individual you deal with?",
    where: 'Where',
    where_do_you_live: 'Where do you live?',
    where_would_you_like_to_start: 'Where would you like to start?',
    which_adviser_is_this_proposal_for: 'Which adviser is this proposal for?',
    which_adviser_is_this_shortlist_for: 'Which adviser is this shortlist for?',
    which_client_is_this_proposal_for: 'Which client is this proposal for?',
    which_client_is_this_shortlist_for: 'Which client is this shortlist for?',
    which_offer_is_this_proposal_for: 'Which offer is this proposal for?',
    why_are_we_collection_this_information: 'Why are we collecting this information?',
    with: 'with',
    with_a_total_proposed_amount_of: 'with a total proposal amount of',
    with_id: 'with ID',
    woodside_corporate_services: 'Woodside Corporate Services',
    workflow_status: 'Workflow Status',
    would_you_like_to_add_this_client_to_both:
        'Would you like to add this client to both your current visibility group and the one linked to the primary contact?',
    would_you_like_to_load_form_data_from_a_previous_session:
        'Would you like to load form data from a previous session?',
    would_you_like_to_provide_any_additional_information: 'Would you like to provide any additional information?',
    would_you_like_to_receive_marketing_communications_from_the_company:
        'Would you like to receive communications and updates directly from the company in addition to via the platform',
    would_you_like_to_retry: 'Would you like to retry?',
    would_you_like_to_view_the_proposal_now: 'Would you like to view the proposal now?',
    would_you_like_to_view_the_proposals_now: 'Would you like to view the proposals now?',
    would_you_like_to_view_this_shortlist_now: 'Would you like to view this shortlist now?',
    wrapper: 'Wrapper',
    write_your_message_with_as_much_details_as_possible:
        'Please write your message in as much detail as possible to help us provide you with the correct information.',
    wysiwyg_debugger: 'WYSIWYG Debugger',
    year: 'Year',
    years: 'Years',
    years_of_upfront_annual_fees: 'Years of upfront annual fees',
    yes: 'Yes',
    yes_cancel: 'Yes, cancel',
    yes_i_am_receiving_advice: 'Yes, I am receiving advice',
    yes_load_previous: 'Yes, load previous',
    yes_this_describes_me: 'Yes, this describes me',
    you_cannot_remove_a_primary_address: 'You cannot remove a primary address',
    you_cant_allot_more_than_the_proposed_amount: "You can't allot more than the proposed amount",
    you_dont_have_permission_to_proceed_with_the_request: "You don't have permission to proceed with the request",
    you_have_decided_to_defer_adding_your_bank_account:
        "You've decided to defer adding your bank account information. You will need to add a bank account before you can withdraw any funds but you can still apply for an investment account.",
    you_have_unsaved_changes_on_this_page:
        'You have unsaved changes on this page. Do you wish to save the changes before continuing?',
    you_must_select_at_least_one_item: 'You must select at least one item',
    you_must_select_one_item: 'You must select one item',
    you_must_tick_the_can_log_in_option_to_send_another_verification_email:
        'You must tick the "Can log in" option to send another verification email',
    you_need_three_years_address_history: 'You need at least three years of address history to make investments.',
    you_need_to_be_the_owner_of_the_investor_organisation_to_apply:
        'You need to be the owner of the Investor organisation to apply for an investment account',
    you_need_to_complete_your_information_before:
        'You need to complete your information before applying for an account.',
    your_account_is_not_yet_authorised: 'Your new account is not yet authorised',
    your_account_isnt_complete_yet: 'Your new account isn’t complete yet!',
    your_account_was_automatically_logged_out:
        'Your account was automatically logged out due to inactivity. If you want to continue your session, you must log back into the platform.',
    your_application_is_being_processed: 'Your application is being processed by our team.',
    your_contact_details: 'Your contact details',
    your_view_of_clients_is_determined_by_a_different_visibility_group:
        'Your view of clients is determined by a different visibility group than the one associated with the primary contact.',
    youre_all_up_to_date: "You're all up to date"
};

export default {
    enums,
    validator,
    validator_names,
    external_urls,
    _page: page,
    ...misc
};
