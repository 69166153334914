import isPublic from '@/router/lib/isPublic';
import Auth from '@/models/Auth';
import User from '@/models/User';

const checkTermsConditions = async (to, from, next) => {
    const auth = Auth();

    if (isPublic(to) || auth.onboarding) {
        next();
    } else {
        if (auth.is_gi) {
            next();
        } else {
            const currentTermsAccepted = localStorage.getItem('currentTermsAccepted') === 'true';

            if (currentTermsAccepted) {
                next();
            } else if (auth.is_initialised) {
                try {
                    const terms = await new User(auth.user).$getTermsConditions();

                    if (!terms || !terms.length) {
                        // The user has no terms to accept
                        next();
                    } else if (terms.every(t => t.accepted)) {
                        // All terms have been accepted
                        localStorage.setItem('currentTermsAccepted', 'true');
                        next();
                    } else {
                        // Redirect to terms and conditions modal
                        next({ query: { tcs: true } });
                    }
                } catch (error) {
                    next();
                }
            } else {
                next();
            }
        }
    }
};

export default checkTermsConditions;
