<template>
    <div>
        <div v-if="!setupLoading && items.length > 1 && $route.name !== 'SettingsIntroduction'" class="px-4">
            <app-tabs :items="items" />
        </div>

        <router-view @validated="onValidated" @load="onLoaded" />
    </div>
</template>

<script>
import Auth from '@/models/Auth';
import { Investor } from '@/models';
import OrganisationTypeEnum from '@/enums/organisation/type';
import getOrganisationType from '@/lib/helpers/getOrganisationType';
import extractStrings from '@/lib/helpers/extractStrings';
import pageErrorRedirect from '@/lib/helpers/pageErrorRedirect';
import isUUID from 'validator/lib/isUUID';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';
import ErrorService from '@/services/ErrorService';

export const tabs = {
    INVESTOR: {
        INDIVIDUAL: [
            'introduction',
            'profile',
            'appropriateness',
            'billing',
            'investor_bank_account',
            'communication',
            'intermediary',
            'security',
            'agreements'
        ],
        TRUST: ['corporate_profile'],
        CORPORATE: ['corporate_profile'],
        PENSION: ['corporate_profile']
    },

    ADVISER: [
        'firm_information',
        'financial',
        'users',
        'external_users',
        'network_setup',
        'branding',
        'visibility_groups'
    ],

    PRODUCT_PROVIDER: [
        'firm_information',
        'users'
        //
    ],

    COMPANY: [
        'firm_information',
        'users'
        //
    ]
};

export const tabKeys = extractStrings(tabs);

const redirectGuard = async (to, from, next) => {
    const route = {
        name: to.name,
        query: to.query
    };

    const auth = Auth();

    const requestedOrg = {
        id: to.query.organisation_id,
        type: to.query.organisation_type ? to.query.organisation_type.toUpperCase() : undefined
    };

    // The user requested a malformed org id
    if (requestedOrg.id && !isUUID(requestedOrg.id)) {
        return pageErrorRedirect(null, `Organisation ID "${requestedOrg.id}" is not a valid UUID`, next);
    }

    // The user requested an unsupported org type
    if (requestedOrg.type && !(requestedOrg.type in OrganisationTypeEnum)) {
        return pageErrorRedirect(null, `Organisation type "${requestedOrg.type}" does not exist`, next);
    }

    // The user requested an org id but didn't specify the org type
    if (requestedOrg.id && !requestedOrg.type) {
        return pageErrorRedirect(null, 'Organisation type missing', next);
    }

    // An investor user requested to view an organisation
    else if (
        auth.organisation_type === OrganisationTypeEnum.INVESTOR &&
        requestedOrg.id &&
        requestedOrg.id !== auth.organisation_id
    ) {
        return pageErrorRedirect(null, 'You do not have permission to view this organisation', next);
    }

    // CP-3653 Disable welcome page flag
    if (route.name === 'SettingsIntroduction' && auth.disable_investor_welcome_page) {
        return next({
            name: 'Dashboard'
        });
    }

    const tabOrgType = requestedOrg.type || auth.organisation_type;
    const tabOrgId = requestedOrg.id || auth.organisation_id;

    const orgTabs = await getOrgTabs(tabOrgType, tabOrgId);

    extractStrings(orgTabs).map(tab => `Settings${upperFirst(camelCase(tab))}`);

    const routeTabName = snakeCase(route.name).toLowerCase().replace('settings_', '');

    if (orgTabs && !orgTabs.includes(routeTabName)) {
        route.name = `Settings${upperFirst(camelCase(orgTabs.find(t => t)))}`;

        if (
            auth.organisation_type === 'ADVISER' &&
            tabOrgType === 'INVESTOR' &&
            route.name === 'SettingsIntroduction'
        ) {
            route.name = 'SettingsProfile';
        }
    }

    if (route.name !== to.name || route.query !== to.query) {
        return next(route);
    }

    next();
};

const getOrgTabs = async (type, id) => {
    let orgTabs = [];

    if (type === OrganisationTypeEnum.INVESTOR) {
        try {
            const investor = await Investor.$get(id);
            const investorStructure = investor.investor_structure;
            orgTabs = tabs[OrganisationTypeEnum.INVESTOR][`${investorStructure}`];
        } catch (error) {
            ErrorService.onError(error, () => getOrgTabs(type, id));
        }
    } else {
        orgTabs = tabs[`${type}`];
    }

    const auth = Auth();

    if (!auth.is_gi) {
        orgTabs = orgTabs.filter(item => item !== 'visibility_groups');
    }

    if (auth.disable_investor_welcome_page) {
        orgTabs = orgTabs.filter(item => item !== 'introduction');
    }

    if (auth.organisation_type === 'ADVISER' && !auth.is_gi && !auth.is_owner && !auth.is_superuser) {
        orgTabs = orgTabs.filter(item => item !== 'financial');
    }

    return orgTabs;
};

export default {
    name: 'Settings',
    components: {
        AppTabs: () =>
            import(
                /* webpackChunkName: "core" */
                '@/components/AppTabs'
            )
    },
    beforeRouteEnter: redirectGuard,
    beforeRouteUpdate: redirectGuard,
    data: () => ({
        items: []
    }),
    beforeDestroy() {
        this.showBreadcrumbs();
    },
    methods: {
        async setup() {
            const orgType = (await getOrganisationType(this.$route)) || this.Auth().organisation_type;
            const orgId = this.$route.query.organisation_id || this.getOrganisationId();

            this.items = (await getOrgTabs(orgType, orgId)).map(item => {
                const name = `Settings${this._.upperFirst(this._.camelCase(item))}`;

                const query = Object.keys(this.$route.query).reduce((acc, key) => {
                    if (key !== 'force_recategorise') {
                        acc[`${key}`] = this.$route.query[`${key}`];
                    }
                    return acc;
                }, {});

                const to = { name, query };

                const loading = name === this.$route.name;

                return {
                    id: name,
                    name: this.$t('enums.settings_tabs.' + this._.snakeCase(item)),
                    badge: null,
                    to,
                    loading
                };
            });

            if (orgType === 'INVESTOR' && this.isAdviser()) {
                this.$store.dispatch('app/addQuerySelector', { key: 'investor_id', value: orgId });
            }

            let breadcrumbs = [];

            if (orgType === 'INVESTOR' && this.isAdviser()) {
                breadcrumbs.push({
                    heading: this.$t('clients'),
                    to: { name: 'PortfolioClients' }
                });
            } else if (orgType === 'ADVISER' && this.isGrowthInvest() && orgId !== this.getOrganisationId()) {
                breadcrumbs.push({
                    heading: this.$t('advisers'),
                    to: { name: 'SystemDataEntryAdvisers' }
                });
            }

            breadcrumbs.push({
                heading: this.$route.meta.heading
            });

            if (this.$route.name === 'SettingsIntroduction') {
                this.hideBreadcrumbs();
            } else {
                this.setBreadcrumbs(breadcrumbs);
                this.showBreadcrumbs();
            }
        },
        setBadgeContent(componentName, content = null) {
            const item = this.items.find(item => item.id === componentName);
            if (item) {
                item.badge = content;
            }
        },
        onValidated(results) {
            if (results && typeof results === 'object') {
                for (const component in results) {
                    const invalid = Object.values(results[`${component}`]).filter(res => res !== true).length;
                    this.setBadgeContent(component, invalid);
                }
            }
        },
        onLoaded(tab) {
            const item = this.items.find(item => item.id === tab);
            if (item) {
                item.loading = false;
            }
        }
    }
};
</script>
