import { BaseModel, Adviser, Address, Branding, Terms } from '@/models';
import MarketingConsentEnum from '@/enums/marketingConsent';
import InvestorCategoryEnum from '@/enums/investor/category';
import config from '@/config';
import RegistrationApi from '@/api/RegistrationApi';

export class Registration extends BaseModel {
    static entity = 'registrations';
    static Api = RegistrationApi;

    static fields() {
        return {
            id: this.string(null).nullable(),
            name: this.string(null).nullable(),
            email: this.string(null).nullable(),
            addresses: this.modelList(Address, []),
            consent_marketing: this.enum(MarketingConsentEnum).nullable(),
            adviser_id: this.string(null).nullable(),
            adviser: this.belongsTo(Adviser, 'adviser_id'),
            adviser_name: this.string(null).nullable(),
            adviser_company_name: this.string(null).nullable(),
            has_adviser: this.boolean(null).nullable(),
            is_advised: this.boolean(false).nullable(),
            investor_type: this.enum(InvestorCategoryEnum).nullable(),
            investor_type_attempts: this.number(0).nullable(),
            on_boarding_status: this.string(null).nullable(),
            signout_url: this.string(null).nullable(),
            created_at: this.string(null).nullable(),
            last_updated: this.string(null).nullable(),
            template: this.attr(null).nullable()
        };
    }

    get is_pending() {
        return this.on_boarding_status === 'PENDING';
    }

    get is_complete() {
        return this.on_boarding_status === 'COMPLETE';
    }

    get is_failed_classification() {
        return this.on_boarding_status === 'FAILED_CLASSIFICATION';
    }

    get is_valid() {
        if (this.exceeded_max_investor_type_attempts) {
            return false;
        }

        if (this.is_failed_classification) {
            return false;
        }

        return true;
    }

    get max_investor_type_attempts_consumed() {
        return this.investor_type_attempts >= this.max_investor_type_attempts;
    }

    get exceeded_max_investor_type_attempts() {
        return this.investor_type_attempts > this.max_investor_type_attempts;
    }

    get max_investor_type_attempts() {
        return config.APP_MAX_ONBOARDING_INVESTOR_TYPE_ATTEMPTS || 3;
    }

    get has_valid_classification_answers() {
        if (!Array.isArray(this.classification_questions) || !this.classification_questions) {
            return false;
        }

        return this.classification_questions.every(question => question.is_valid);
    }

    get is_certified_sophisticated() {
        return this.investor_type === InvestorCategoryEnum.CERTIFIED_SOPHISTICATED;
    }

    get is_elective_professional() {
        return this.investor_type === InvestorCategoryEnum.ELECTIVE_PROFESSIONAL;
    }

    get is_high_net_worth() {
        return this.investor_type === InvestorCategoryEnum.HIGH_NET_WORTH;
    }

    get is_professional() {
        return this.investor_type === InvestorCategoryEnum.PROFESSIONAL;
    }

    get is_retail_advised() {
        return this.investor_type === InvestorCategoryEnum.RETAIL_ADVISED;
    }

    get is_retail_restricted() {
        return this.investor_type === InvestorCategoryEnum.RETAIL_RESTRICTED;
    }

    get is_self_cert_sophisticated() {
        return this.investor_type === InvestorCategoryEnum.SELF_CERT_SOPHISTICATED;
    }

    get is_retail() {
        return this.is_retail_restricted || this.is_retail_advised;
    }

    get required_declarations() {
        if (!this.template || !this.template.declarations) {
            return [];
        }

        let declarations = [];

        if (this.is_retail_advised) {
            declarations = this.template.declarations.filter(
                declaration => declaration.investor_type === InvestorCategoryEnum.RETAIL_ADVISED
            );
        } else if (this.is_retail_restricted) {
            declarations = this.template.declarations.filter(
                declaration => declaration.investor_type === InvestorCategoryEnum.RETAIL_RESTRICTED
            );
        } else if (this.is_self_cert_sophisticated) {
            declarations = this.template.declarations.filter(
                declaration => declaration.investor_type === InvestorCategoryEnum.SELF_CERT_SOPHISTICATED
            );
        } else if (this.is_certified_sophisticated) {
            declarations = this.template.declarations.filter(
                declaration => declaration.investor_type === InvestorCategoryEnum.CERTIFIED_SOPHISTICATED
            );
        } else if (this.is_high_net_worth) {
            declarations = this.template.declarations.filter(
                declaration => declaration.investor_type === InvestorCategoryEnum.HIGH_NET_WORTH
            );
        } else if (this.is_elective_professional) {
            declarations = this.template.declarations.filter(
                declaration => declaration.investor_type === InvestorCategoryEnum.ELECTIVE_PROFESSIONAL
            );
        } else if (this.is_professional) {
            declarations = this.template.declarations.filter(
                declaration => declaration.investor_type === InvestorCategoryEnum.PROFESSIONAL
            );
        }

        declarations.sort((a, b) => a.sort_order - b.sort_order);

        return declarations;
    }

    get required_terms() {
        if (!this.template || !this.template.terms) {
            return [];
        }

        return this.template.terms.map(t => (t instanceof BaseModel ? t : new Terms(t)));
    }

    get branding() {
        return Branding.find('public');
    }

    get is_branded() {
        return this.branding && this.branding.custom_app_url;
    }
}

export default Registration;
