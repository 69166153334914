import { Collection, Terms } from '@/models';
import TermsStatusEnum from '@/enums/terms/status';
import i18n from '@/lib/i18n';
import OrganisationTypeEnum from '@/enums/organisation/type';

export class SystemTermsCollection extends Collection {
    static type = 'SystemTermsCollection';
    static endpoint = Terms.api.index;
    static model = Terms;

    get default_headers() {
        return ['status', 'type', 'effective_at', 'summary'];
    }

    get headers() {
        return [
            this.createHeader('control', '', {
                format: 'control',
                width: '7rem',
                persistent: true
            }),
            this.createHeader('status', i18n.t('status'), {
                filterable: false,
                width: '8rem',
                format: 'enum',
                enum: TermsStatusEnum,
                enumA11yKey: 'enums.terms_status',
                enumTypes: {
                    [TermsStatusEnum.DRAFT]: 'success',
                    [TermsStatusEnum.APPROVED]: 'error',
                    [TermsStatusEnum.SUPERCEDED]: 'grey'
                }
            }),
            this.createHeader('type', i18n.t('organisation_type'), {
                filterable: true,
                width: '12rem',
                format: 'enum',
                enum: OrganisationTypeEnum,
                enumA11yKey: 'enums.organisation_type'
            }),
            this.createHeader('effective_at', i18n.t('effective_at'), {
                width: '10rem',
                filterable: true,
                sortable: true,
                format: 'date'
            }),
            this.createHeader('summary', i18n.t('summary'), {
                width: '100%',
                format: 'wysiwyg'
            }),
            this.createHeader('created_at', i18n.t('created_at'), {
                width: '9rem',
                filterable: true,
                sortable: true,
                format: 'datetime',
                formatOptions: {
                    showTime: true
                },
                active: false
            }),
            this.createHeader('created_by_id', i18n.t('created_by'), {
                width: '14rem',
                classList: ['no-wrap'],
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/User')
                },
                transform: data => data.item?.created_by?.name,
                fallback: i18n.t('system'),
                active: false
            }),
            this.createHeader('last_updated', i18n.t('last_updated_at'), {
                width: '9rem',
                filterable: true,
                sortable: true,
                format: 'datetime',
                formatOptions: {
                    showTime: true
                },
                active: false
            }),
            this.createHeader('last_updated_by_id', i18n.t('last_updated_by'), {
                width: '14rem',
                classList: ['no-wrap'],
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/User')
                },
                transform: data => data.item?.last_updated_by?.name,
                fallback: i18n.t('system'),
                active: false
            })
        ];
    }

    get preset_filters() {
        return {
            status: `${this.filterOperator('is_not')}:${TermsStatusEnum.APPROVED}`
        };
    }

    get filter_where_status_is_approved() {
        return {
            status: `${this.filterOperator('is')}:${TermsStatusEnum.APPROVED}`
        };
    }
}

export default SystemTermsCollection;
