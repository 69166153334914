/* eslint-disable max-len */

export const page = {
    error: {
        browser_incompatible:
            'It appears that you are currently using an outdated version of your browser. To ensure optimal security, we strongly advise updating your browser to benefit from the latest security enhancements.',
        safari_browser_incompatible:
            'The browser and/or browser version you’re running is known to cause issues with our platform due to a defect which was fixed in later versions of Safari. We recommend that you update your browser to a more recent version, or use an alternative such as Chrome, Firefox or Edge.'
    },
    reports: {
        heading: 'Reports',
        adviser_documents_activity_description:
            'This report provides details of actions taken on documents in the platform library related to your organisation.',
        adviser_marketing_activity_description:
            'This report provides details of browsing activity on the platform carried out by users in your organisation. This includes viewing and updating items like offers, shortlists and proposals.',
        adviser_security_activity_description:
            'This report provides details of security events related to users in your organisation. These include logins, password resets and key account changes.',
        investor_documents_activity_description:
            'This report provides details of actions taken on documents in the platform library associated with your clients.',
        investor_flow_activity_description: 'This report provides step-by-step information on the proposal workflow.',
        investor_marketing_activity_description:
            'This report provides details of browsing activity on the platform carried out by your clients. This includes viewing and updating items like offers, shortlists and proposals.',
        investor_security_activity_description:
            'This report provides details of security events related to your clients. These include logins, password resets and key account changes',
        pending_applications: {
            alert_text:
                "This report shows investors which have applied for an investment account, but have no 'Investment account authorised' date set. Once the authorised date is set, the account will drop off this report."
        },
        tax_year: {
            alert_text:
                'Tax relief amounts calculated by this report are based on the maximum tax relief available for the investments and does not take into account the personal circumstances of the investor. The tax status of each investment is taken as reported. Future actions either by the investor or by the investee company may affect the tax status and tax relief which can be claimed.'
        }
    },
    settings: {
        bank_accounts: {
            authorised_with_no_bank_account_notice:
                'No bank account details were provided during the application process. You will not be able to withdraw any funds from your account until your bank details have been provided and verified.',
            authorised_with_no_bank_account_notice_title:
                'Important Notice: Bank Account Details Required for Fund Withdrawal',
            deferral_checkbox_label:
                'I would like to open my GrowthInvest account without a linked bank account, and I understand that I will need to add this at a later stage.',
            james_brearley_account_name: 'James Brearley & Sons Ltd',
            james_brearley_account_number: '10 49 16 89',
            james_brearley_address:
                'Online Services Team (GrowthInvest), James Brearley & Sons, Walpole House, Unit 2 Burton Road, Blackpool, FY4 4NW',
            james_brearley_address_label:
                'Cheques should be made payable to "James Brearley & Sons Ltd" and sent to the following address with a cover letter stating your name and client number.',
            james_brearley_bank_name: 'Royal Bank of Scotland',
            james_brearley_description:
                'This account is to be used for making investment in any listed products. This may include VCT & AIM products and ISA transfers only. You must use your James Brearley Client Number followed by your surname as payment reference for Online Bank Transfers to ensure funds are allocated correctly. If you do not, this will cause unnecessary delays in cash allocation.',
            james_brearley_description_warning:
                'If you do not know your James Brearley Client Number (N.B. this is different from your GrowthInvest Client reference and account numbers), please ask your intermediary or email clientservices@growthinvest.com before making payment',
            james_brearley_heading: 'James Brearley GrowthInvest Bank Account Details',
            james_brearley_sort_code: '16 14 12',
            james_brearley_transaction_reference_description:
                'Please always add a reference to each transaction. Please use the following format for the reference: James Brearley Client Number_Surname',
            subtitle:
                'Please provide details of the bank account to which any distributions, for example dividends, should be paid. This should usually be in the name of the bank account holder. Please note that additional verification checks may be required, including a copy of a bank statement to confirm details in advance of the transfer of any funds.',
            transfer_details_subtitle:
                "Please make a note of the following bank account details for when you're ready to transfer funds into your GrowthInvest account.",
            transfer_details_title: 'Transfer Details',
            woodside_account_name: 'WCSL GrowthInvest Client Account',
            woodside_account_number: '10 40 96 65',
            woodside_address:
                "GrowthInvest Team, Woodside Corporate Services Ltd, 1st Floor, 12-14 Mason's Avenue, London, EC2V 5BT",
            woodside_address_label:
                'Cheques should be made payable to "WCSL GrowthInvest Client Account" and sent to the following address:',
            woodside_bank_name: 'Bank of Scotland',
            woodside_description:
                'This account is to be used when making an investment in any unlisted (SEIS, EIS, IHT/BR excl. AIM) and private equity products only. Any money deposited will be shown as a cash balance on your account. To ensure funds are allocated correctly and in a timely manner, please use the reference stated below when making online payments.',
            woodside_heading: 'Woodside GrowthInvest Bank Account Details',
            woodside_sort_code: '80 20 00',
            woodside_transaction_reference_description:
                'The transaction reference will be your initials followed by your surname. Please always add a reference to each transaction. Please use the following format for the reference: Initials_Surname'
        },
        billing: {
            alert_text:
                'All charges entered on this form will be stored on our system and will be used to validate all charge requests made by you or set up on your behalf. All fees facilitated by GrowthInvest will be applied quarterly, one month in arrears, on the overall portfolio. GrowthInvest will send copies of relevant information, and accept reasonable instruction from your authorised financial intermediary unless instructed otherwise in writing.',
            alert_title: 'Please see the below notes for further information for adviser charge requests',
            fee_documents_description: 'Fees and charges documents can be downloaded here:',
            fee_documents_empty: 'There are currently no documents available to download, please come back later.',
            gi_fee_description:
                'An annual fee is applied as a percentage of your overall portfolio value. The standard charge is 0.25% per annum, with a minimum annual charge of £250. Any agreed variation to the current standard terms will be detailed on the Growthinvest Fees and Charges document.',
            intermediary_fee_description:
                'GrowthInvest can facilitate the payment of fees to your adviser or financial intermediary. Please specify any agreed ongoing adviser remuneration.',
            subtitle:
                'Please review all fees and charges detailed below. All the latest GrowthInvest account and administration charges are available on the Fees and charges document.'
        },
        branding: {
            avatar: 'Also known as the "Profile Picture", your brand avatar is a smaller version of your brand logo that represents your brand on the platform. It is typically displayed alongside your brand name and serves as a visual identifier for you organisation.',
            background:
                "The background image should be a high-quality JPG file that captures the essence of the company's brand identity. It should be visually appealing and non-distracting, ensuring that it does not interfere with the client self-registration process.",
            pdf_cover:
                'The PDF cover image should be a high-quality JPG file. The cover image will be displayed on the first page of both the portrait and landscape PDF exports from the platform. The images you upload must be A4 size (21cm x 29.7cm) or have a ratio of 1:1.41 to prevent distortion.',
            colours:
                'The colours you choose should be consistent with your brand and should pass the recommended contrast accessibility test to ensure that people with moderately low vision can use the platform.',
            favicon:
                "The favicon appears in the web browser's address bar, next to the page title, and on bookmarks or tabs. The purpose of the favicon is to provide a visual representation of the platform in the browser's interface, making it easier for users to identify.",
            logo: "Before you update your platform logo, it's important to consider the impact this change may have on your brand identity and recognition. Your logo is often the first thing that clients see so it's essential to ensure it's either a high-quality transparent PNG or an SVG.",
            preview:
                'The preview will help to get a feel for what it will look like when you apply the changes to the platform branding.'
        },
        communication: {
            growthinvest_marketing_updates_tooltip:
                'Clients and users can opt in to receiving direct GrowthInvest updates on relevant product information, and newsletters about the company aimed at our clients',
            growthinvest_technical_regulatory_updates_tooltip:
                'This is a mandatory selection for all users, and is used only for required communications',
            investment_events_tooltip: 'A specific notification on the progress of an investment application',
            investment_notifications_tooltip:
                'Specific notifications on regulatory communications, specific investment updates and the status of tax certificates',
            registration_events_tooltip:
                'Communications for initial registration including welcome and verification emails',
            subtitle:
                'There are a range of different notifications which can be activated according to client preferences on the GrowthInvest portal. Please tick the relevant boxes below to receive proactive notifications for each category.',
            transaction_events_tooltip: 'A specific notification based on type of transaction'
        },
        external_users: {
            heading: 'External Users'
        },
        users: {
            heading: 'Users'
        },
        visibility_groups: {
            active_clients_list_description:
                'Associated users will only be able to see data on the platform from the clients defined in this list:',
            clients_description:
                'To add clients to this visibility group, click or drag and drop the required client onto the visibility group list.',
            description:
                "Visibility groups provide a way to restrict an adviser's visibility on the platform to a defined list of investors. Investors that don't belong to any visibility group will only be visible to advisers which have not been assigned a visibility group.",
            users_description: 'The following users have been assigned to this visibility group:'
        }
    },
    transaction_action: {
        CHANGE_AMOUNTS_AND_SHARES: {
            PURCHASE:
                'Update the amounts for this purchase. Note that this will update the initial investment amounts on the holding.',
            SALE: 'Update the amounts for this sale. The holding disposition status will be updated to reflect the number of shares sold in this transaction and will be opened/closed accordingly. Note that if future sales close this position, the number of shares you can sell will be limited by those transactions. If necessary, delete them first.'
        },
        CHANGE_FUND_AMOUNT:
            'Enter a new initial investment amount for this fund investment. This will update the tranche and the transactions to match.',
        CHANGE_PRODUCT:
            'Select a new product for this transaction. This will update the holding and all related transactions (e.g. any related sell or dividend transactions).',
        CHANGE_SALE_AMOUNT: 'Update the amount of this transaction.',
        DELETE_TRANSACTION: {
            relationship_warning:
                'Deleting this transaction will remove all transactions, holdings and, where appropriate, tranches related to this transaction. This potentially has a wide-ranging effect and you should proceed with EXTREME CAUTION.',
            warning:
                'Deleting this transaction will remove all trace of this transaction ever having existed in the system. This should only be used when a transaction has been entered in error.'
        },
        TOGGLE_ON_OFF_PLATFORM: {
            OTHER: 'Change the on/off platform status.',
            PURCHASE:
                'Change the on/off platform status. Note that this will update the position to reflect the correct platform status.'
        }
    },
    remote_application_action: {
        DELETE_REMOTE_APPLICATION: {
            warning:
                'Deleting this remote application will remove all trace of this application ever having existed in the system. This should only be used when an application has been entered in error.'
        }
    },
    bulk_share_allotment: {
        heading: 'Bulk Share Allotment',
        description:
            'Allot shares to multiple holdings at once. Start by selecting the product you wish to allot shares for.',
        SELECT_HOLDINGS: {
            heading: 'Select Holdings',
            description: 'Select the holdings you wish to allot shares to.'
        },
        PREPARE_HOLDINGS: {
            heading: 'Prepare Holdings',
            description:
                'Review the holdings you have selected and enter the number of shares you wish to allot to each holding.'
        }
    },
    dri_management: {
        heading: 'DRI Management',
        description: 'Manage dividend reinvestment plans.'
    },
    proposal: {
        special_event: {
            PROPOSAL_APPLICATION_SIGNED: {
                title: 'Proposal Application Signed',
                description: 'Provide the date when the application was signed:'
            },
            PROPOSAL_FUNDS_TRANSFERRED: {
                title: 'Funds Transferred',
                description: 'Provide the date when the funds were transferred:'
            }
        }
    },
    proposals: {
        heading: 'Proposals',
        description: 'Proposals are a way to keep track of potential investments.'
    },
    shortlists: {
        heading: 'Investment Shortlists',
        description: 'Shortlists are a way to keep track of investments that you are interested in.'
    },
    portfolio_dashboard: {
        heading: 'Portfolio Dashboard',
        description: 'View a summary of your portfolio.'
    },
    portfolio_detail: {
        heading: 'Portfolio Detail',
        summary: {
            heading: 'Summary',
            description: 'View a summary of your portfolio.'
        },
        single_company: {
            heading: 'Single Company Investments',
            description: 'View Single Company investments in your portfolio.'
        },
        eis_seis: {
            heading: 'EIS and SEIS Funds',
            description: 'View EIS and SEIS fund investments in your portfolio.'
        },
        vct: {
            heading: 'Venture Capital Trusts',
            description: 'View Venture Capital Trust investments in your portfolio.'
        },
        iht: {
            heading: 'IHT and Estate Planning Services',
            description: 'View IHT and Estate Planning Services investments in your portfolio.'
        },
        pmi: {
            heading: 'Private Market Investments',
            description: 'View Private Market investments in your portfolio.'
        }
    },
    client_registrations: {
        heading: 'Client Registrations',
        description: 'View a list of client registrations.'
    },
    clients: {
        heading: 'Clients',
        description: 'View a list of clients.'
    },
    transactions: {
        heading: 'Transactions',
        description: 'View a list of transactions.'
    },
    library: {
        heading: 'Library',
        description: 'View a list of documents.'
    }
};

export default page;
