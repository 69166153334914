import BaseModel from '@/models/BaseModel';
import createHeader from '@/components/table/lib/createHeader';
import getFilterOperator from '@/lib/helpers/getFilterOperator';
import hash from '@/lib/helpers/hash';

export class Collection extends BaseModel {
    static entity = 'collections';

    /**
     * Model object for the collection item.
     */
    static model = null;

    /**
     * Asynchronous function to get a list of records from the API.
     */
    static endpoint = null;

    /**
     * The type of the collection class.
     */
    static type = 'Collection';

    static fields() {
        return {
            _preventTransform: this.boolean(false),
            id: this.string(),
            offset: this.number(null).nullable(),
            limit: this.number(null).nullable(),
            sort_by: this.attr([]),
            filters: this.attr({}),
            config: this.attr({}),
            response: this.attr(null),
            loading: this.attr(null),
            item_ids: this.attr([]),
            type: this.string(null).nullable()
        };
    }

    has_model(model) {
        return this.constructor.model === model;
    }

    get cast() {
        return this.constructor.model;
    }

    get item() {
        return id => {
            if (!this.constructor.model) {
                throw new Error(`Collection model not set on collection class: '${this.constructor.type}'`);
            }

            return this.constructor.model.$get(id);
        };
    }

    get search() {
        return (criteria, filters = {}, abortGroup = null) => {
            if (!this.constructor.model) {
                throw new Error(`Collection model not set on collection class: '${this.constructor.type}'`);
            }

            if (abortGroup) {
                this.constructor.model.abortSearch(abortGroup);
            }

            return this.constructor.model.search(criteria, filters, { abortGroup });
        };
    }

    static async updateItemInCollections(itemId, newItemData) {
        const collections = await Collection.query()
            .where('item_ids', ids => ids.includes(itemId))
            .get();

        for (const c8n of collections) {
            c8n.response.data = c8n.response.data.map(item => {
                if (item.id === itemId) {
                    item = { ...item, ...newItemData };
                }

                return item;
            });
            await Collection.insertOrUpdate({ data: c8n });
        }
    }

    static async removeItemInCollections(itemId) {
        const collections = await Collection.query()
            .where('item_ids', ids => ids.includes(itemId))
            .get();

        for (const c8n of collections) {
            c8n.response.data = c8n.response.data.filter(item => {
                return item.id !== itemId;
            });

            if (c8n.response.metadata.total <= c8n.response.data.length) {
                c8n.response.metadata.total = c8n.response.data.length;
            } else {
                c8n.response.metadata.total = c8n.response.metadata.total - 1;
            }

            await Collection.insertOrUpdate({ data: c8n });
        }
    }

    static async removeCollectionWithItem(itemId) {
        const collections = await Collection.query()
            .where('item_ids', ids => ids.includes(itemId))
            .get();

        for (const c8n of collections) {
            Collection.delete(c8n.id);
        }
    }

    get headers() {
        return [];
    }

    get default_headers() {
        return [];
    }

    get pdf_headers() {
        return this.default_headers || [];
    }

    get bulk_update_headers() {
        return [
            createHeader('control', '', {
                format: 'control',
                width: '8rem',
                align: 'left',
                persistent: true
            })
        ];
    }

    get preset_offset() {
        return null;
    }

    get preset_limit() {
        return null;
    }

    get preset_sort_by() {
        return [];
    }

    get preset_filters() {
        return {};
    }

    get preset_config() {
        return {};
    }

    /**
     * Get the collection data from the API.
     *
     * Offset - The number of records to skip.
     * Limit - The number of records to return.
     * SortBy - The field to sort by.
     * Filters - The filters to apply.
     * Config - The API configuration to apply.
     *
     * Where a preset value is not provided on the collection class, the value will be taken
     * from the collection instance. If the instance does not have the value, the value will be
     * taken from the arguments.
     */
    get request() {
        if (!this.constructor.endpoint) {
            return () =>
                Promise.resolve({
                    data: [],
                    metadata: {
                        total: 0
                    }
                });
        }

        return async (offset = null, limit = null, sort_by = [], filters = {}, config = {}) => {
            if (this.preset_offset !== null && this.preset_offset !== undefined) {
                offset = this.preset_offset;
            } else if (this.offset !== null && this.offset !== undefined) {
                offset = this.offset;
            }

            if (this.preset_limit !== null && this.preset_limit !== undefined) {
                limit = this.preset_limit;
            } else if (this.limit !== null && this.limit !== undefined) {
                limit = this.limit;
            }

            if (this.preset_sort_by && this.preset_sort_by.length > 0) {
                sort_by = [...this.preset_sort_by, ...sort_by];
            } else if (this.sort_by && this.sort_by.length > 0) {
                sort_by = [...this.sort_by, ...sort_by];
            }

            if (this.preset_filters && Object.keys(this.preset_filters).length > 0) {
                filters = { ...this.preset_filters, ...filters };
            } else if (this.filters && Object.keys(this.filters).length > 0) {
                filters = { ...this.filters, ...filters };
            }

            if (this.preset_config && Object.keys(this.preset_config).length > 0) {
                config = { ...this.preset_config, ...config };
            } else if (this.config && Object.keys(this.config).length > 0) {
                config = { ...this.config, ...config };
            }

            const id = hash([this.constructor.type, offset, limit, sort_by, filters, config]);

            let found = await Collection.find(id);

            let response = null;

            if (found) {
                if (found.loading) {
                    await found.loading;
                }
            } else {
                const request = this.constructor.endpoint(offset, limit, sort_by, filters, config);

                await Collection.insert({
                    data: {
                        id,
                        offset,
                        limit,
                        sort_by,
                        filters,
                        config,
                        loading: request,
                        type: this.constructor.type
                    }
                });

                response = await request.catch(async error => {
                    await this.on_error(id);
                    throw error;
                });

                await this.on_complete(id, response);
            }

            found = await Collection.find(id);

            if (found) {
                return found.response;
            }

            if (response && response.status === 'success') {
                return response;
            }
        };
    }

    createHeader(key, label, options = {}) {
        return createHeader(key, label, options);
    }

    filterOperator(operator) {
        return getFilterOperator(operator);
    }

    async transform_item(item) {
        return item;
    }

    async on_complete(id, response) {
        if (Array.isArray(response.data) && response.data.length > 0) {
            this.item_ids = response.data.map(item => item.id);
            if (!this._preventTransform) {
                response.data = await Promise.all(response.data.map(item => this.transform_item(item)));
            }
        }

        await Collection.update({
            where: id,
            data: { response, loading: false, item_ids: this.item_ids }
        });
    }

    async on_error(id) {
        await Collection.delete(id);
    }
}

export default Collection;
