import { Model } from '@vuex-orm/core';
import { Branding } from '@/models';
import GrowthInvestRoleEnum from '@/enums/growthInvest/role';
import uuid from '@/lib/helpers/uuid';
import PermissionEnum from '@/enums/auth/permission';
import toDate from '@/lib/helpers/toDate';
import clock from '@/lib/clock';
import UserRoleEnum from '@/enums/user/role';
import ErrorService from '@/services/ErrorService';
import { redirectToLogin, getHomeRoute } from '@/lib/auth';
import BrandingApi from '@/api/BrandingApi';
import MiscApi from '@/api/MiscApi';
import InvestorStructureEnum from '@/enums/investor/structure';

export class AuthModel extends Model {
    static entity = 'auth';

    static fields() {
        return {
            id: this.string(null),
            _user: this.attr(null),
            _organisation: this.attr(null),
            _account: this.attr(null),
            _investor: this.attr(null),
            _adviser: this.attr(null),
            _onboarding: this.boolean(false),
            branding_id: this.string('public')
        };
    }

    set user(payload) {
        this._user = payload ? payload : null;

        if (payload && 'id' in payload) {
            this.id = payload.id;
        } else {
            this.id = null;
        }

        this.$save();
    }

    get user() {
        return this._user;
    }

    set organisation(payload) {
        this._organisation = payload ? payload : null;
        this.$save();
    }

    get organisation() {
        return this._organisation;
    }

    set account(payload) {
        this._account = payload ? payload : null;
        this.$save();
    }

    get account() {
        return this._account;
    }

    set investor(payload) {
        this._investor = payload ? payload : null;
        this.$save();
    }

    get investor() {
        return this._investor;
    }

    set adviser(payload) {
        this._adviser = payload ? payload : null;
        this.$save();
    }

    get adviser() {
        return this._adviser;
    }

    set onboarding(payload) {
        this._onboarding = payload ? payload : false;
        this.$save();
    }

    get onboarding() {
        return this._onboarding;
    }

    branding(id = null) {
        return Branding.find(id || this.branding_id) || Branding.find('public') || new Branding();
    }

    // Getters

    get user_id() {
        if (!this.has_user) {
            return null;
        }

        return this.user.id;
    }

    get user_name() {
        if (!this.has_user) {
            return null;
        }

        return this.user.name;
    }

    get user_email() {
        if (!this.has_user) {
            return null;
        }

        return this.user.email;
    }

    get adviser_id() {
        if (this.is_investor) {
            if (this.has_account) {
                return this.account.adviser_id;
            } else if (this.accounts.length) {
                return this.accounts[0].adviser_id;
            } else {
                return null;
            }
        } else if (this.is_adviser) {
            return this.organisation_id;
        }

        return null;
    }

    get linked_users() {
        if (!this.has_user) {
            return [];
        }
        return this.user.linked_users;
    }

    get organisations() {
        if (!this.has_user) {
            return [];
        }

        let orgs = [...this.user.organisations];

        for (const link of this.linked_users) {
            orgs.push(...link.organisations);
        }

        orgs = orgs.filter((org, index, self) => self.indexOf(org) === index);

        orgs.sort((a, b) => a.name.localeCompare(b.name));

        return orgs;
    }

    get organisation_id() {
        if (!this.organisation) {
            return null;
        }
        return this.organisation.id;
    }

    get organisation_type() {
        if (!this.organisation) {
            return null;
        }

        return this.organisation.type;
    }

    get organisation_roles() {
        if (!this.organisation) {
            return null;
        }

        return this.organisation.roles;
    }

    get account_id() {
        if (!this.has_account) {
            return null;
        }

        return this.account.adviser_id;
    }

    get accounts() {
        if (!this.organisation) {
            return [];
        }

        return this.organisation.accounts;
    }

    get category_expires_on() {
        if (!this.organisation) {
            return null;
        }

        return this.organisation.category_expires_on;
    }

    get category_expired() {
        if (!this.category_expires_on) {
            ErrorService.logSentryEvent('Category expires on is falsy', this.category_expires_on);
            return true;
        }

        const date = toDate(this.category_expires_on, false);

        if (!date) {
            ErrorService.logSentryEvent('Category expires on is not a valid date', this.category_expires_on);
            return true;
        }

        return date.isBefore(clock());
    }

    get hostname() {
        if (this.is_investor && this.has_account && this.account.base_url) {
            return this.account.base_url;
        } else if (this.has_organisation && this.organisation.base_url) {
            return this.organisation.base_url;
        }
        return window.location.hostname;
    }

    get scope_organisation_name() {
        let name = null;

        if (this.has_account) {
            name = this.account.adviser_name;
        } else if (this.accounts && this.accounts.length) {
            name = this.accounts[0].adviser_name;
        }

        return name;
    }

    get scope_organisation_id() {
        let id = null;

        if (this.has_account) {
            id = this.account.adviser_id;
        } else if (this.accounts && this.accounts.length) {
            id = this.accounts[0].adviser_id;
        }

        return id;
    }

    get headers() {
        const headers = {};

        if (!this.is_logged_in) {
            return headers;
        }

        if (this.is_investor) {
            headers['X-GI-Role'] = this.organisation_id;
            headers['X-GI-Scope'] = this.scope_organisation_id;
        } else {
            headers['X-GI-Role'] = this.organisation_id;
            headers['X-GI-Scope'] = this.organisation_id;
        }

        return headers;
    }

    get permissions() {
        if (!this.organisation) {
            return [];
        }

        return this.organisation.permissions;
    }

    get roles() {
        if (!this.organisation) {
            return [];
        }
        return this.organisation.roles;
    }

    get signout_url() {
        if (!this.has_user) {
            return null;
        }
        return this.user.signout_url;
    }

    get home_route() {
        return getHomeRoute(this.organisation);
    }

    // Helpers

    get is_investor() {
        return this.organisation_type === 'INVESTOR';
    }

    get is_individual_investor() {
        if (!this.is_investor || !this.investor) {
            return false;
        }

        return this.investor.investor_structure === InvestorStructureEnum.INDIVIDUAL;
    }

    // Execution Only
    get is_xo_investor() {
        if (!this.is_investor || !this.investor) {
            return false;
        }

        return this.investor.adviser_accounts.some(aa => {
            return aa.advised === false && aa.adviser?.id === this.account_id;
        });
    }

    get is_advised_investor() {
        if (!this.is_investor || !this.investor) {
            return false;
        }

        return this.investor.adviser_accounts.some(aa => {
            return aa.advised === true && aa.adviser?.id === this.account_id;
        });
    }

    get is_investor_authorised() {
        if (!this.is_investor) {
            return false;
        }
        if (!this.investor) {
            return false;
        }

        return this.investor.investment_account_authorised_at !== null;
    }

    get is_adviser() {
        return this.organisation_type === 'ADVISER';
    }

    get is_provider() {
        return this.organisation_type === 'PRODUCT_PROVIDER';
    }

    get is_company() {
        return this.organisation_type === 'COMPANY';
    }

    get is_gi() {
        return Object.keys(GrowthInvestRoleEnum).some(role => this.has_role(role));
    }

    get is_initialised() {
        if (!this.organisation) {
            return false;
        }
        if (this.is_investor && this.accounts.length && !this.has_account) {
            return false;
        }
        return true;
    }

    get is_logged_in() {
        return this.has_user;
    }

    get has_user() {
        return !!this.user;
    }

    get has_organisation() {
        return !!this.organisation;
    }

    get has_account() {
        return !!this.account;
    }

    get has_branding() {
        return this.branding_id && Branding.find(this.branding_id);
    }

    get has_accounts() {
        return this.accounts > 0;
    }

    get has_valid_hostname() {
        return this.hostname && this.hostname === window.location.hostname;
    }

    get has_role() {
        return role => this.roles.includes(role);
    }

    get has_permission() {
        return permission => this.permissions.includes(permission);
    }

    get can() {
        return this.has_permission;
    }

    get is_owner() {
        return this.has_role(UserRoleEnum.OWNER);
    }

    get is_superuser() {
        // Adviser
        if (this.has_role(UserRoleEnum.SUPERUSER)) {
            return true;
        }

        // Provider
        if (this.has_role(UserRoleEnum.SUPERUSER2)) {
            return true;
        }

        return this.is_gi;
    }

    get disable_investor_welcome_page() {
        return this.adviser && this.adviser.disable_investor_welcome_page;
    }

    get disable_cobranded_reports() {
        return this.adviser && this.adviser.disable_cobranded_reports;
    }

    // Setup

    async ping() {
        return await new MiscApi().methods.getCurrentUser();
    }

    async login(newTab = false) {
        return redirectToLogin(
            {
                rd: window.location.origin,
                app_uri: window.location.origin
            },
            newTab
        );
    }

    getLocalOrganisation() {
        const currentOrg = localStorage.getItem('currentOrganisation');

        if (!currentOrg) {
            return null;
        }

        if (typeof currentOrg === 'string' && currentOrg.includes(',')) {
            return currentOrg.split(',').pop();
        }

        return currentOrg;
    }

    getLocalAccount() {
        const currentAccount = localStorage.getItem('currentAccount');

        if (!currentAccount) {
            return null;
        }

        if (typeof currentAccount === 'string' && currentAccount.includes(',')) {
            return currentAccount.split(',').pop();
        }

        return currentAccount;
    }

    async setCurrentUser() {
        this.user = await new MiscApi().methods.getCurrentUser();
        this.onboarding = false;

        const currentOrg = this.getLocalOrganisation();

        if (currentOrg) {
            const organisation = this.user.organisations.find(org => org.id === currentOrg);

            if (!organisation) {
                this.organisation = null;
            } else if (organisation !== this.organisation) {
                this.organisation = organisation;
            }

            if (organisation && organisation.type === 'INVESTOR') {
                const currentAccount = this.getLocalAccount();
                const account = this.organisation.accounts.find(acc => acc.adviser_id === currentAccount);

                if (!account) {
                    this.account = null;
                } else if (!this.account || account.adviser_id !== this.account.adviser_id) {
                    this.account = account;
                }
            } else {
                this.account = null;
            }
        }

        return this;
    }

    setUser(user, organisation_id = null, account_id = null) {
        this.user = user;

        if (!this.user) {
            this.organisation = null;
            this.account = null;
            this.onboarding = false;
            this.investor = null;
            this.adviser = null;
        } else {
            if (organisation_id) {
                this.organisation = user.organisations.find(org => org.id === organisation_id);
            } else {
                this.organisation = user.organisations[0];
            }

            if (this.organisation.type === 'INVESTOR') {
                if (account_id) {
                    this.account = this.organisation.accounts.find(org => org.id === account_id);
                } else {
                    this.account = this.organisation.accounts[0];
                }
            } else {
                this.account = null;
            }

            this.onboarding = false;
        }
    }

    getHostOrganisations() {
        const hostname = window.location.hostname;

        if (!this.has_user) {
            return [];
        }

        return this.user.organisations.filter(org => {
            if (org.type === 'INVESTOR') {
                return org.accounts.find(acc => acc.base_url === hostname);
            } else {
                return org.base_url === hostname;
            }
        });
    }

    getHostAccounts() {
        const hostname = window.location.hostname;

        if (!this.organisation) {
            return [];
        }

        return this.organisation.accounts.filter(acc => acc.base_url === hostname);
    }

    async setBranding() {
        const brands = [];

        try {
            const response = await new BrandingApi().methods.get();

            for (const org of response.organisations) {
                if (org.type === 'INVESTOR') {
                    if ('accounts' in org) {
                        for (const acc of org.accounts) {
                            brands.push({
                                id: acc.adviser_id,
                                name: acc.adviser_name,
                                _text: acc.branding.text,
                                _colours: acc.branding.colours,
                                _images: acc.branding.images,
                                _dark: acc.branding.dark,
                                _custom_app_url: acc.branding.custom_app_url
                            });
                        }
                    }
                } else if (org.type === 'ADVISER') {
                    brands.push({
                        id: org.id,
                        name: org.name,
                        _text: org.branding.text,
                        _colours: org.branding.colours,
                        _images: org.branding.images,
                        _dark: org.branding.dark,
                        _custom_app_url: org.branding.custom_app_url
                    });
                }
            }

            for (const linked of response.linked_users) {
                for (const org of linked.organisations) {
                    if (org.type === 'INVESTOR') {
                        if ('accounts' in org) {
                            for (const acc of org.accounts) {
                                brands.push({
                                    id: acc.adviser_id,
                                    name: acc.adviser_name,
                                    _text: acc.branding.text,
                                    _colours: acc.branding.colours,
                                    _images: acc.branding.images,
                                    _dark: acc.branding.dark,
                                    _custom_app_url: acc.branding.custom_app_url
                                });
                            }
                        }
                    } else if (org.type === 'ADVISER') {
                        brands.push({
                            id: org.id,
                            name: org.name,
                            _text: org.branding.text,
                            _colours: org.branding.colours,
                            _images: org.branding.images,
                            _dark: org.branding.dark,
                            _custom_app_url: org.branding.custom_app_url
                        });
                    }
                }
            }

            if (brands.length === 0 || !this.adviser_id) {
                throw new Error('No branding found');
            }
        } catch (error) {
            const publicBranding = await new MiscApi().methods.getPublicBranding();
            brands.push({
                id: 'public',
                name: 'Public',
                _text: publicBranding.text,
                _colours: publicBranding.colours,
                _images: publicBranding.images,
                _dark: publicBranding.dark,
                _custom_app_url: publicBranding.custom_app_url
            });
        }

        Branding.insert({ data: brands });

        this.branding_id = this.adviser_id || 'public';
        this.$save();
    }
}

export const Auth = params => {
    let auth = AuthModel.all().find(au => !!au.id);

    if (!auth) {
        auth = new AuthModel(params);
    }

    return auth;
};

export const mockUser = (params = {}) => {
    return {
        id: uuid(36),
        name: 'Mock User',
        email: 'mock-user@email.com',
        title: null,
        gi_ref: null,
        signout_url: 'https://logout.test',
        organisations: [mockOrganisation()],
        linked_users: [],
        ...params
    };
};

export const mockOrganisation = (params = {}) => {
    if ('type' in params && params.type === 'INVESTOR' && !('accounts' in params)) {
        params.accounts = [mockAccount()];
    }

    return {
        id: uuid(36),
        name: 'Mock Organisation',
        type: 'ADVISER',
        roles: ['PLATFORM_SUPERADMIN'],
        permissions: Object.keys(PermissionEnum),
        base_url: 'app.growthinvest.localhost',
        ...params
    };
};

export const mockAccount = (params = {}) => {
    return {
        adviser_id: uuid(36),
        adviser_name: 'Mock Adviser',
        base_url: 'app.growthinvest.localhost',
        ...params
    };
};

export default Auth;
